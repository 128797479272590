import React, { useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Grow, Slide } from '@material-ui/core';
import TopPanel from '../../components/TopPanel';
import Section from '../../components/Section';
import ContentBox from '../../components/ContentBox';
import useDimension from '../../hooks/useDimension';
import StatsSection from './Stats';
import Button from '../../components/Button';
import useGlobalStyles from '../../theme/useGlobalStyles';

import DeepLiquidityImage from '../../static/images/bg_deep_liquidity.png';
import PriceEngineImage from '../../static/images/bg_price_engine.png';
import MainPrimeImage from '../../static/images/bg_prime_main.png';
import AccountWidgetImage from '../../static/images/account_widget.png';
import TradeWidgetImage from '../../static/images/trade_widget.png';
import AccessImage from '../../static/images/bg_access.png';
import CustodyImage from '../../static/images/bg_safe.svg';
import AccessMobileImage from '../../static/images/bg_access_mobile.png';
import VipServiceImage from '../../static/images/bg_vip_service.png';

const useStyles = makeStyles((theme: any) => ({
  container: {
    width: 1136,
    overflowX: 'hidden',
    margin: '0 auto',
    paddingTop: theme.spacing(12),
    [theme.breakpoints.sm]: {
      width: 'auto',
      paddingTop: theme.spacing(6),
    },
  },
  root: {
    marginBottom: theme.spacing(10),
  },
  btn: {
    marginTop: theme.spacing(5.5),
    width: 'max-content',
  },
  demo: {
    marginLeft: theme.spacing(6),
    marginRight: theme.spacing(6),
    marginBottom: theme.spacing(12),
    margin: '0 auto',
    height: 450,
    position: 'relative',
    backgroundImage: `url(${MainPrimeImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100%',
    filter: 'drop-shadow(0px 4px 40px rgba(0, 0, 0, 0.5))',
    [theme.breakpoints.sm]: {
      height: 416,
      marginBottom: 0,
    },
    [theme.breakpoints.xs]: {
      marginLeft: -100,
      marginRight: 0,
      backgroundSize: 'cover',
      height: 550,
    },
  },
  primeImage: {
    [theme.breakpoints.sm]: {
      width: 654,
    },
  },
  accountWidget: {
    width: 279,
    position: 'absolute',
    top: 97,
    right: 60,
    [theme.breakpoints.sm]: {
      width: 182,
      top: 90,
      right: 100,
    },
    [theme.breakpoints.xs]: {
      top: 73,
      right: 'unset',
      left: 93,
    },
  },
  tradeWidget: {
    width: 215,
    position: 'absolute',
    top: 104,
    left: 90,
    [theme.breakpoints.sm]: {
      width: 180,
      top: 72,
      left: 99,
    },
    [theme.breakpoints.xs]: {
      top: 167,
      right: 0,
      left: 'unset',
    },
  },
  sectionWidgets: {
    '& .section-image': {
      height: 460,
      maxWidth: 540,
    },
    [theme.breakpoints.sm]: {
      '& .section-image': {
        height: 'auto',
      },
    },
  },
  sectionAccess: {
    '& p': {
      maxWidth: 801,
    },
  },
  custodySection: {
    marginBottom: 104,
    '& .section-image': {
      marginRight: 100,
    },
    [theme.breakpoints.sm]: {
      marginBottom: 48,
      '& .section-title': {
        maxWidth: 710,
        textAlign: 'center',
      },
      '& .section-image': {
        display: 'none',
      },
    },
  },
  vipServiceSection: {
    overflowX: 'hidden',
    paddingTop: 104,
    paddingBottom: 104,
    backgroundColor: '#0D0D0D',
    [theme.breakpoints.sm]: {
      paddingTop: 48,
      paddingBottom: 48,
      overflowX: 'hidden',
    },
  },
  vipService: {
    marginTop: 0,
    width: '100%',
    '& .section-content': {
      width: '100%',
      marginLeft: 150,
    },
    '& .section-text': {
      maxWidth: 495,
    },
    '& .section-image': {
      marginRight: theme.spacing(10),
      maxWidth: 718,
    },
    [theme.breakpoints.sm]: {
      '& .section-content': {
        marginLeft: 0,
      },
      '& .section-image': {
        marginRight: 0,
      },
    },
    [theme.breakpoints.xs]: {
      '& .section-image': {
        marginLeft: 240,
        height: 329,
        width: 'auto',
      },
    },
  },
}));

export default function MainPage() {
  const globalClasses = useGlobalStyles();
  const classes = useStyles();
  const { isMobile, isLargeScreen } = useDimension();

  useEffect(() => {
    setTimeout(() => {
      if (window.screenY > 40) {
        window.scroll(0, 0);
      }
    }, 500);
  }, []);

  return (
    <main>
      <div className={classes.container}>
        <Grow in timeout={3000}>
          <div>
            <TopPanel className={classes.root}>
              <h1 className={globalClasses.pageTitle}>Cex.io Prime</h1>
              <div className={globalClasses.pageDescription}>
                A complete institutional-grade ecosystem from CEX.IO Group, a
                trusted,
                <br />
                regulated top-tier crypto-fiat services provider.
              </div>
            </TopPanel>
          </div>
        </Grow>
        <Grow in timeout={2000}>
          <section>
            <div className={classes.demo}>
              <Slide in direction="up" timeout={2000}>
                <img
                  src={AccountWidgetImage}
                  className={classes.accountWidget}
                  alt={AccountWidgetImage}
                />
              </Slide>
              <Slide in direction="up" timeout={2000}>
                <img
                  src={TradeWidgetImage}
                  className={classes.tradeWidget}
                  alt={TradeWidgetImage}
                />
              </Slide>
            </div>
          </section>
        </Grow>
        <StatsSection />
        <Section
          className={classes.sectionWidgets}
          title="Deepest liquidity"
          text="CEX.IO Prime Liquidity executes order sizes across the market's leading liquidity platforms at the best price available. We provide tight spread and the tightest liquidity in the industry, with the widest range of assets and markets. "
          image={DeepLiquidityImage}
        />
        <Section
          className={classes.sectionWidgets}
          title="Best price matching engine"
          text="The CEX.IO Prime Liquidity best price execution algorithm will automatically route your order to be fulfilled at the best price on a market, splitting it in the most efficient way among the 
        top trading venues in the industry."
          image={PriceEngineImage}
          imageSide="right"
        />
        <Section
          mainAxis="column"
          title="Easy Access"
          text="Open your account and receive single-point access to aggregated liquidity that is consolidated from top tier liquidity providers and exchanges. Use our multi-access web portal to execute orders and keep track of all activities, or dive deep into API integration with REST, WebSocket and FIX 4.4, 
        all available for seamless technical set up."
          className={classes.sectionAccess}
          image={isMobile ? AccessMobileImage : AccessImage}
        />
        <Section
          title="Institutional-grade custody with insurance"
          text="Secure, insured storage for your digital assets that is customizable for your specific business needs. All digital assets are held in trust - to the benefit of our clients."
          image={CustodyImage}
          className={classes.custodySection}
        >
          <>
            <Button
              variant="colored"
              className={clsx(globalClasses.pageAction, classes.btn)}
              component="a"
              href="/contacts"
            >
              Become a client
            </Button>
          </>
        </Section>
      </div>
      <ContentBox
        maxWidth={isLargeScreen ? '1920px' : 'inherit'}
        className={classes.vipServiceSection}
      >
        <Section
          imageSide="right"
          title="Personal VIP Service"
          text="Quality service is our utmost priority. We put clients’ needs first and foremost. All CEX.IO Prime clients are assigned an experienced account manager to build a strong and mutually beneficial relationship."
          image={VipServiceImage}
          className={classes.vipService}
        >
          <>
            <Button
              variant="colored"
              className={clsx(globalClasses.pageAction, classes.btn)}
              component="a"
              href="/contacts"
            >
              Become a client
            </Button>
          </>
        </Section>
      </ContentBox>
    </main>
  );
}
