import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { NavLink } from 'react-router-dom';

const useStyles = makeStyles((theme: any) => ({
  root: {
    height: 48,
    display: 'inline-block',
    textAlign: 'center',
    fontSize: 12,
    lineHeight: '18px',
    fontWeight: 'bold',
    padding: '15px 23px 14px',
    color: theme.palette.text.white,
    textTransform: 'uppercase',
    textDecoration: 'none',
    transition: 'all 300ms ease 0s',
    '&:focus, &:hover': {
      outline: 'none !important',
      borderColor: '#2BCBD7 !important',
      background: '#2BCBD7 !important'
    },
    '&:active': {
      outline: 'none !important',
      borderColor: '#43DCE7 !important',
      background: '#43DCE7 !important'
    },
    '&:disabled': {
      borderColor: '#2F2F2F !important',
      background: '#2F2F2F !important',
      color: 'rgba(108, 108, 108, 0.7)'
    },
    '&.size-default': {
      width: ({ width }: any) => width,
      [theme.breakpoints.xs]: {
        paddingLeft: 10,
        paddingRight: 10
      }
    },
    '&.size-large': {
      padding: '29px 45px',
      fontSize: 18,
      fontWeight: 700,
      height: 'auto',
      [theme.breakpoints.xs]: {
        paddingLeft: 18,
        paddingRight: 18
      }
    },
    '&.size-medium': {
      padding: '15px 12px',
      fontSize: 14,
      fontWeight: 700,
      height: 'auto',
      lineHeight: '20px'
    },
    '&.variant-default': {
      background: 'transparent',
      border: `1px solid ${theme.palette.border.gray}`
    },
    '&.variant-colored': {
      background: theme.palette.link.main,
      border: `1px solid ${theme.palette.link.main}`
    },
    '&.variant-white': {
      background: theme.palette.background.secondary,
      border: `1px solid ${theme.palette.background.primary}`,
      color: theme.palette.text.dark
    }
  }
}))

interface IProps {
  type?: 'submit' | 'button';
  size?: 'default' | 'medium' | 'large';
  variant?: 'default' | 'colored' | 'white';
  onClick?: any;
  children: any;
  width?: number;
  className?: any;
  disabled?: boolean;
  component?: 'a' | 'button';
  href?: string;
  external?: boolean;
  sameWindow?: boolean;
}

export default function Button({ width, size = 'default', children, variant = 'default', className, component, href, external, sameWindow, ...rest }: IProps) {
  const classes = useStyles({ width });

  if (component === 'a' && href) {
    if (external) {
      return (
        <a
          href={href}
          target={sameWindow ? undefined : '_blank'}
          className={clsx(classes.root, `size-${size}`, `variant-${variant}`, className)}
        >
          {children}
        </a>
      );
    }
    return (
      <NavLink to={href} className={clsx(classes.root, `size-${size}`, `variant-${variant}`, className)}>
        {children}
      </NavLink>
    );
  }

  return (
    <button
      className={clsx(classes.root, `size-${size}`, `variant-${variant}`, className)}
      {...rest}
    >
      {children}
    </button>
  )
}
