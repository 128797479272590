import MuiOutlinedInput from './MuiOutlinedInput';
import MuiButton from './MuiButton';
import MuiSelect from './MuiSelect';
import MuiTypography from './MuiTypography';
import MuiMenu from './MuiMenu';
import MuiSkeleton from './MuiSkeleton';

const overrides = {
  MuiOutlinedInput,
  MuiButton,
  MuiSelect,
  MuiTypography,
  MuiMenu,
  MuiSkeleton,
};

export default overrides;
