import { useCallback, useState } from 'react';
import Api from '../../../api';

export type AssetType = {
  currency: string;
  name: string;
  stackingAPY: string;
  uiPrecision: number;
  price: number;
};

export const useGetStakingAssets = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [data, setData] = useState<AssetType[]>([]);

  const fetchStakingAssets = useCallback(async function () {
    setLoading(true);
    setError(false);
    try {
      const { data: result } = await Api.get('/get_staking_assets');
      setData(Array.isArray(result?.data) ? result.data : []);
    } catch (error) {
      setError(true);
      console.error("[ERROR]: can't load staking assets", error);
    }
    setLoading(false);
  }, []);

  return {
    fetchStakingAssets,
    loading,
    error,
    data,
  };
};
