import { useEffect, useCallback, RefObject } from 'react';

function useScrollListener(
  ref: RefObject<HTMLElement>,
  listenerCallback: () => void,
  negativePadding?: number,
) {
  const handleScroll = useCallback(function () {
    const position = window.scrollY;
    const componentRect = ref.current?.getBoundingClientRect();
    if (position >= ref.current?.offsetTop! - componentRect?.height! - (negativePadding ?? 0)) {
      listenerCallback();
    }
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', () => {
      handleScroll();
    });

    return () => {
      window.removeEventListener('scroll', () => {
        handleScroll();
      });
    };
  }, []);
}

export default useScrollListener;
