import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Field } from 'react-final-form';

import SelectIcon from '../icons/select.svg';

export const useInputStyles = makeStyles((theme: any) => ({
  root: {
    marginBottom: 24,
    position: 'relative'
  },
  label: {
    color: theme.palette.text.dark,
    fontSize: 12,
    lineHeight: '18px',
    marginBottom: 8,
    display: 'block',
    '&.error': {
      color: 'red'
    }
  },
  input: {
    border: '1px solid #6C6C6C',
    height: 48,
    padding: '14px 16px',
    fontSize: 14,
    lineHeight: '20px',
    width: '100%',
    '&:active, &:focus': {
      outline: 'none'
    },
    '&.error': {
      borderColor: 'red'
    }
  },
  textarea: {
    height: 96,
    resize: 'none'
  },
  select: {
    padding: 0
  },
  error: {
    position: 'absolute',
    fontSize: 12,
    bottom: -16,
    left: 0,
    color: 'red',
    whiteSpace: 'nowrap'
  }
}))

function InputField({ input, label, placeholder, textarea, required, meta, inputRef, ...rest }: any) {
  const classes = useInputStyles();
  const hasError = meta.touched && meta.invalid;

  return (
    <div className={classes.root}>
      <label className={clsx(classes.label, { error: hasError })}>{label}{required && '*'}</label>
      {textarea
        ? (
          <textarea
            className={clsx(classes.input, classes.textarea, { error: hasError })}
            {...input}
            placeholder={placeholder}
            ref={inputRef}
          />
        ) : (
          <input
            className={clsx(classes.input, { error: hasError })}
            {...input}
            placeholder={placeholder}
            ref={inputRef}
            {...rest}
          />
        )}
      {hasError && <div className={classes.error}>{meta.error}</div>}
    </div>
  )
}

interface IProps {
  name: string;
  label: string;
  placeholder?: string;
  textarea?: boolean;
  required?: boolean;
  maxLength?: number;
  validate?: any;
  type?: string;
  id?: string;
  inputRef?: any;
}

export default function Input({ name, ...rest }: IProps) {
  return (
    <Field
      name={name}
      component={InputField}
      {...rest}
    />
  )
}
