import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    width: '100%'
  },
  circle: {
    color: '#1BB6C1',
  },
}));

interface ILoaderProps {
  loading: boolean;
  children?: any | any[];
  height?: number;
  width?: number;
  style?: any;
}

export default function Loader(props: ILoaderProps) {
  const { loading, children } = props;
  const classes = useStyles();
  return loading
    ? (
      <div
        className={classes.root}
        style={{
          height: props.height ? `${props.height}px` : undefined,
          width: props.width ? `${props.width}px` : undefined,
          ...props.style
        }}
      >
        <CircularProgress classes={{ circle: classes.circle }} />
      </div>
    )
    : (children || null);
}
