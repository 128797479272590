export default {
  text: {
    white: '#fff',
    dark: '#000',
    light: '#353535',
    grey: 'rgba(0,0,0,0.7)',
    green: '#0ABF64',
    red: '#E65069',
    desk: '#999'
  },
  link: {
    main: '#1BB6C1',
    light: 'rgba(27,182,193,0.1)'
  },
  background: {
    primary: '#000',
    light: '#161616',
    lighter: '#262626',
    secondary: '#fff'
  },
  border: {
    gray: '#6C6C6C'
  },
}