import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: any) => ({
  container: {
    overflowX: 'hidden',
    paddingLeft: 150,
    paddingRight: 150,
    [theme.breakpoints.md]: {
      paddingLeft: theme.spacing(6),
      paddingRight: theme.spacing(6),
    },
    [theme.breakpoints.sm]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  heading: {
    maxWidth: 860,
    margin: '80px auto 96px auto',
  },
  title: {
    marginBottom: theme.spacing(2),
    fontSize: 56,
    lineHeight: '64px',
    textAlign: 'center',
    '& span': {
      color: '#1BB6C1',
    },
  },
  subtitle: {
    margin: '0 auto',
    width: 670,
    color: theme.palette.text.desk,
    fontSize: 18,
    lineHeight: '26px',
    textAlign: 'center',
  },
  stakingAssets: {
    marginBottom: 104,
    display: 'grid',
    gridTemplateRows: '1fr',
    gridTemplateColumns: '1fr 1fr 1fr',
    gap: '24px',
    [theme.breakpoints.sm]: {
      gridTemplateRows: '1fr 1f',
      gridTemplateColumns: '1fr 1fr',
    },
  },
  widgetSection: {
    marginBottom: 140,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  widgetDescription: {
    maxWidth: 535,
    [theme.breakpoints.up(1440)]: {
      marginRight: 111,
    },
  },
  widgetTitle: {
    marginBottom: theme.spacing(1),
    fontSize: 48,
    lineHeight: '56px',
    '& span': {
      color: '#1BB6C1',
    },
  },
  widgetText: {
    color: theme.palette.text.desk,
    fontSize: 18,
    lineHeight: '26px',
  },
  advantagesSection: {
    marginBottom: 140,
    display: 'flex',
    justifyContent: 'space-between',
  },
  advantagesItem: {
    display: 'flex',
    alignItems: 'center',
    '& p': {
      marginBottom: theme.spacing(1),
      fontSize: 56,
      lineHeight: '64px',
    },
    '& span': {
      color: theme.palette.text.desk,
      fontSize: 18,
      fontWeight: 700,
      lineHeight: '26px',
    },
  },
  advantageIcon: {
    marginRight: theme.spacing(3),
    padding: 23,
    borderRadius: 50,
    backgroundColor: 'rgba(27, 182, 193, 0.2)',
  },
  offeringSection: {
    marginBottom: 104,
  },
  offeringTitle: {
    marginBottom: theme.spacing(3),
    fontSize: 48,
    lineHeight: '56px',
    textAlign: 'center',
  },
  offers: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: '24px',
  },
  contactForm: {
    width: 585,
    paddingBottom: 104,
    margin: '0 auto',
  },
  formTitle: {
    marginBottom: theme.spacing(2),
    fontSize: 48,
    lineHeight: '56px',
    textAlign: 'center',
  },
  formSubtitle: {
    marginBottom: theme.spacing(5),
    color: theme.palette.text.desk,
    fontSize: 18,
    fontWeight: 400,
    lineHeight: '26px',
    textAlign: 'center',
  },
}));
