export const required = (value: any, errStart?: string) => (value ? undefined : `${errStart}is required`);

export const maxLength = (value: any, l: number, errStart?: string) =>
  (value && value.length > l ? `${errStart}should be not longer then ${l} symbols` : undefined);
export const phoneLength = (value: any) =>
  (value && (value.length < 9 || value.length > 16) ? `Phone number should contain from 8 to 15 digits` : undefined);

const emailRegexp = /^(([^<>()\[\]\\.,;:!&%#'\s@"]+(\.[^<>()\[\]\\.,;:!&%#'\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const isEmail = (value: string) => !value || emailRegexp.test(value.toLowerCase()) ? undefined : 'Email entered incorrectly';

const wordRegexp = /^[a-z]+$/;
export const isWord = (value: string, errStart?: string) => !value || wordRegexp.test(value.toLowerCase()) ? undefined : `${errStart}should contain only letters`;

export const composeValidators = (...validators: any[]) => (value: any) => validators.reduce(
  (error, validator) => error || validator(value), undefined
);
