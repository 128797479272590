import React from 'react';
import clsx from 'clsx';
import { Slide } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Section from '../../components/Section';
import ContentBox from '../../components/ContentBox';
import HouseIcon from '../../components/UI/HouseIcon';
import StatsIcon from '../../components/UI/StatsIcon';
import BtcIcon from '../../components/UI/BtcIcon';
import EmployeeIcon from '../../components/UI/EmployeeIcon';
import useDimension from '../../hooks/useDimension';

import SolutionsImage from '../../static/images/bg_solutions.png';
import AccountsImage from '../../static/images/bg_accounts.png';
import OpenAccountsImage from '../../static/images/bg_open_accounts.png';
import AccountWidgetImage from '../../static/images/account_widget.png';
import TradeWidgetImage from '../../static/images/trade_widget.png';

const useStyles = makeStyles((theme: any) => ({
  container: {
    overflowX: 'hidden',
    [theme.breakpoints.md]: {
      paddingLeft: theme.spacing(6),
      paddingRight: theme.spacing(6),
    },
    [theme.breakpoints.sm]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  image: {
    '& > img': {
      width: '100%',
      height: 595,
      [theme.breakpoints.sm]: {
        height: 415,
      },
      [theme.breakpoints.xs]: {
        height: 328,
        width: 640,
        marginLeft: 175,
      },
    },
  },
  solutionSection: {
    marginTop: 104,
    display: 'flex',
    '& h6': {
      marginBottom: theme.spacing(1),
      fontSize: 72,
      lineHeight: '84px',
      [theme.breakpoints.sm]: {
        fontSize: 64,
        lineHeight: '72px',
      },
      [theme.breakpoints.xs]: {
        fontSize: 40,
        lineHeight: '48px',
      },
    },
    '& .section-content': {
      marginBottom: theme.spacing(9),
    },
    '& .section-text': {
      maxWidth: 590,
    },
    [theme.breakpoints.md]: {
      paddingBottom: 0,
    },
    [theme.breakpoints.sm]: {
      marginTop: 0,
      width: '100%',
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      paddingBottom: 0,
      '& .section-content': {
        marginBottom: theme.spacing(6),
      },
    },
  },
  digitalSection: {
    width: '100%',
    marginTop: 16,
    '& .section-text': {
      maxWidth: 600,
    },
    [theme.breakpoints.md]: {
      marginTop: theme.spacing(5),
      '& .section-title': {
        maxWidth: 500,
      },
      '& .section-text': {
        maxWidth: 458,
      },
    },
    [theme.breakpoints.sm]: {
      padding: 0,
      '& .section-title': {
        marginBottom: theme.spacing(1),
        fontSize: 36,
      },
      '& .section-text': {
        maxWidth: 600,
        width: 'auto',
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
      },
    },
    [theme.breakpoints.xs]: {
      '& .section-title': {
        marginBottom: theme.spacing(2),
        width: 200,
        fontSize: 28,
      },
      '& .section-text': {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
      },
    },
  },
  digitalContainer: {
    marginRight: 134,
    [theme.breakpoints.md]: {
      marginRight: 0,
    },
    [theme.breakpoints.sm]: {
      marginTop: 0,
      marginBottom: 130,
      marginRight: 0,
    },
    [theme.breakpoints.xs]: {
      marginTop: 0,
    },
  },
  digitalImage: {
    marginRight: theme.spacing(8),
    height: 407,
    width: '100%',
    maxWidth: 700,
    position: 'relative',
    backgroundImage: `url(${AccountsImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundPositionX: 'center',
    backgroundSize: 'contain',
    [theme.breakpoints.sm]: {
      marginRight: 0,
      order: 1,
    },
    [theme.breakpoints.xs]: {
      height: 327,
      marginLeft: -30,
      marginRight: 0,
      backgroundPositionX: 'right',
      backgroundSize: 'cover',
    },
  },
  digitalAccountWidget: {
    position: 'absolute',
    top: 103,
    right: 22,
    height: 220,
    [theme.breakpoints.md]: {
      top: 30,
      right: -10,
    },
    [theme.breakpoints.sm]: {
      top: 130,
      right: -20,
    },
    [theme.breakpoints.xs]: {
      height: 128,
      top: 74,
      right: 62,
    },
  },
  digitalTradeWidget: {
    position: 'absolute',
    top: 160,
    left: 167,
    height: 320,
    [theme.breakpoints.sm]: {
      top: 186,
      left: 94,
    },
    [theme.breakpoints.xs]: {
      height: 236,
      top: 178,
      right: 'unset',
      left: 50,
    },
  },
  financialContainer: {
    paddingBottom: theme.spacing(14),
    [theme.breakpoints.lg]: {
      marginLeft: 150,
    },
    [theme.breakpoints.md]: {
      marginLeft: theme.spacing(6),
    },
    [theme.breakpoints.sm]: {
      marginLeft: 0,
      marginTop: 80,
      paddingBottom: theme.spacing(4),
    },
  },
  financialSection: {
    width: '100%',
    [theme.breakpoints.md]: {
      '& .section-title': {
        maxWidth: 458,
      },
      '& .section-text': {
        maxWidth: 458,
      },
    },
    [theme.breakpoints.sm]: {
      paddingTop: 0,
      paddingRight: 0,
      paddingLeft: 0,
      '& .section-title': {
        marginBottom: theme.spacing(1),
        fontSize: 36,
      },
      '& .section-text': {
        maxWidth: 'initial',
        paddingLeft: theme.spacing(7),
        paddingRight: theme.spacing(7),
      },
    },
    [theme.breakpoints.xs]: {
      '& .section-title': {
        marginBottom: theme.spacing(2),
        fontSize: 28,
      },
      '& .section-text': {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
      },
    },
  },
  financialImage: {
    marginLeft: 128,
    order: 1,
    height: 407,
    width: '100%',
    position: 'relative',
    backgroundImage: `url(${AccountsImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundPositionX: 'left',
    backgroundSize: 'contain',
    [theme.breakpoints.md]: {
      marginLeft: 48,
    },
    [theme.breakpoints.sm]: {
      marginLeft: theme.spacing(6),
    },
    [theme.breakpoints.xs]: {
      backgroundSize: 'cover',
    },
  },
  financialAccountWidget: {
    position: 'absolute',
    top: 73,
    left: -30,
    height: 220,
    [theme.breakpoints.md]: {
      top: 120,
      left: -10,
    },
    [theme.breakpoints.sm]: {
      top: 103,
      left: -8,
    },
    [theme.breakpoints.xs]: {
      height: 200,
      top: 50,
      right: 'unset',
      left: -8,
    },
  },
  financialTradeWidget: {
    position: 'absolute',
    top: 163,
    left: 305,
    height: 320,
    [theme.breakpoints.md]: {
      top: 200,
      left: 405,
    },
    [theme.breakpoints.sm]: {
      top: 164,
      right: 90,
      left: 'unset',
    },
    [theme.breakpoints.xs]: {
      height: 290,
      top: 212,
      right: 8,
      left: 'unset',
    },
  },
  brokerContainer: {
    backgroundColor: '#ffffff',
  },
  clientContainer: {
    backgroundColor: '#ffffff',
  },
  clientSection: {
    marginTop: 104,
    marginBottom: 104,
    width: '100%',
    '& .section-image': {
      maxWidth: 780,
      height: 407,
      marginLeft: 144,
    },
    '& .section-text': {
      maxWidth: 515,
    },
    '& .section-title': {
      color: '#000',
    },
    [theme.breakpoints.md]: {
      '& .section-image': {
        marginLeft: 0,
        marginRight: theme.spacing(2),
        maxWidth: 630,
        height: 300,
      },
      '& .section-content': {
        marginLeft: theme.spacing(4),
      },
      '& .section-text': {
        maxWidth: 401,
      },
    },
    [theme.breakpoints.sm]: {
      marginTop: 0,
      paddingBottom: 0,
      marginRight: 0,
      marginBottom: theme.spacing(6),
      '& .section-image': {
        marginLeft: 0,
        width: 'inherit',
      },
      '& .section-content': {
        marginLeft: 0,
      },
      '& .section-title': {
        marginBottom: theme.spacing(1),
        fontSize: 36,
      },
      '& .section-text': {
        maxWidth: 663,
      },
    },
    [theme.breakpoints.xs]: {
      '& .section-title': {
        marginBottom: theme.spacing(2),
        fontSize: 28,
      },
      '& .section-image': {
        height: 320,
        width: 'max-content',
        marginLeft: 240,
      },
    },
  },
}));

function Solutions() {
  const { isLargeScreen } = useDimension();
  const classes = useStyles({ isLargeScreen });

  return (
    <main className={classes.container}>
      <ContentBox maxWidth={isLargeScreen ? '1420px' : '1132px'}>
        <Section
          runAnimationImmediately
          className={clsx(classes.solutionSection, classes.image)}
          title="Solutions"
          mainAxis="column"
          text="CEX.IO Prime is designed to cater to a variety of businesses. We can help you deal with any challenges."
          image={SolutionsImage}
        />
      </ContentBox>
      <ContentBox
        maxWidth={isLargeScreen ? '1420px' : 'inherit'}
        className={classes.digitalContainer}
      >
        <Section
          negativePadding={350}
          className={clsx(classes.image, classes.digitalSection)}
          title="Digital Asset Projects"
          imageSide="left"
          icon={<HouseIcon />}
          text="It is the ultimate solution for exchanges, trading desks, wallets and other fintech businesses dealing with cryptocurrency assets. Get the deepest liquidity and automate the entire flow with our institutional-grade APIs and market-leading web portal."
          imageNode={
            <div className={classes.digitalImage}>
              <Slide in direction="up" timeout={2000}>
                <img
                  src={AccountWidgetImage}
                  className={classes.digitalAccountWidget}
                  alt={AccountWidgetImage}
                />
              </Slide>
              <Slide in direction="up" timeout={2000}>
                <img
                  src={TradeWidgetImage}
                  className={classes.digitalTradeWidget}
                  alt={TradeWidgetImage}
                />
              </Slide>
            </div>
          }
        />
      </ContentBox>
      <ContentBox
        maxWidth={isLargeScreen ? '1420px' : 'inherit'}
        className={classes.financialContainer}
      >
        <Section
          className={(classes.image, classes.financialSection)}
          title="Financial Institutions"
          imageSide="right"
          icon={<StatsIcon />}
          text={
            'Our software is designed for asset management firms, family offices, hedge funds, challenger banks and other institutions.<br/><br/> Multi-user access, seamless and instant execution, advanced order types, and hundreds of digital assets in the portfolio connected to fiat rails with all major fiat currencies.<br/><br/>Post-trade settlement and secure custody offer is at the core of the service. '
          }
          imageNode={
            <div className={classes.financialImage}>
              <Slide in direction="up" timeout={2000}>
                <img
                  src={AccountWidgetImage}
                  className={classes.financialAccountWidget}
                  alt={AccountWidgetImage}
                />
              </Slide>
              <Slide in direction="up" timeout={2000}>
                <img
                  src={TradeWidgetImage}
                  className={classes.financialTradeWidget}
                  alt={TradeWidgetImage}
                />
              </Slide>
            </div>
          }
        />
      </ContentBox>
      <ContentBox
        maxWidth={isLargeScreen ? '1420px' : 'inherit'}
        className={classes.clientContainer}
      >
        <Section
          className={classes.clientSection}
          title="Private Clients"
          imageSide="left"
          icon={<EmployeeIcon />}
          text={
            'A complete white glove service with dedicated account managers and subject matter experts is always at the disposal of HNWIs, asset managers and family offices. Our team will assist clients with any amount of expertise with digital assets that help you build a portfolio in the most effective and efficient way.'
          }
          image={AccountsImage}
        />
      </ContentBox>
    </main>
  );
}

export default Solutions;
