import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

import Button from '../../components/Button';
import useGlobalStyles from '../../theme/useGlobalStyles';

const useStyles = makeStyles((theme: any) => ({
  root: {
    textAlign: 'center',
    [theme.breakpoints.sm]: {
      background: theme.palette.background.primary,
    }
  }
}))

interface IProps {
  bgImage?: any;
  bgPosition?: string;
  children: any | any[];
  hideButton?: boolean;
  className?: string;
}

export default function TopPanel({ children, hideButton, className }: IProps) {
  const classes = useStyles();
  const globalClasses = useGlobalStyles();

  return (
    <div className={clsx(classes.root, globalClasses.container, className)}>
      {children}
      {!hideButton &&
      <Button variant="colored" className={globalClasses.pageAction} component="a" href="/contacts">Become a client</Button>}
    </div>
  );
}
