import React from 'react';
import clsx from 'clsx';
import { Slide } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import useGlobalStyles from '../../theme/useGlobalStyles';

const useStyles = makeStyles((theme: any) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.sm]: {
      marginBottom: theme.spacing(10),
      paddingLeft: theme.spacing(6),
      paddingRight: theme.spacing(6),
    },
    [theme.breakpoints.xs]: {
      marginTop: -100,
      alignItems: 'baseline',
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      '& li:not(:last-child)': {
        marginBottom: theme.spacing(0),
      },
    },
  },
  item: {
    zIndex: 3,
    [theme.breakpoints.xs]: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  title: {
    marginBottom: theme.spacing(1),
    fontSize: 80,
    lineHeight: '96px',
    color: '#ffffff',
    [theme.breakpoints.sm]: {
      fontSize: 64,
      lineHeight: '72px',
    },
    [theme.breakpoints.xs]: {
      fontSize: 32,
      lineHeight: '44px',
    },
  },
  description: {
    fontSize: 12,
    lineHeight: '26px',
    color: theme.palette.text.desk,
  },
}));

export default function StatsSection() {
  const classes = useStyles();
  const globalClasses = useGlobalStyles();

  return (
    <Slide direction="up" in timeout={1000}>
      <ul className={clsx(globalClasses.container, classes.root)}>
        <li className={classes.item}>
          <p className={classes.title}>50+</p>
          <span className={classes.description}>Institutional Clients</span>
        </li>
        <li className={classes.item}>
          <p className={classes.title}>$4B+</p>
          <span className={classes.description}>Trading Volume</span>
        </li>
        <li className={classes.item}>
          <p className={classes.title}>1M+</p>
          <span className={classes.description}>Trades Performed</span>
        </li>
      </ul>
    </Slide>
  );
}
