import React, { useEffect, useState, useCallback, useMemo } from 'react';
import {
  Grow,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  InputAdornment,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';
import ContentBox from '../../components/ContentBox';
import Number from '../../components/Number';
import Button from '../../components/Button';
import { TablePlaceholder } from '../../components/Table';
import HighlightText from '../../components/HighlightText';
import CurrencyIcon from '../../components/UI/CurrencyIcon';
import useDimension from '../../hooks/useDimension';
import NotFoundIcon from '../../static/images/icon_not_found.svg';
import Api from '../../api';
import type { TradingPair } from '../../typings';

const useStyles = makeStyles((theme: any) => ({
  container: {
    overflowX: 'hidden',
  },
  topPanel: {
    paddingTop: theme.spacing(11),
    paddingBottom: theme.spacing(11),
    textAlign: 'center',
    '& h1': {
      fontSize: 72,
      fontWeight: 400,
      lineHeight: '84px',
      textTransform: 'uppercase',
    },
    '& p': {
      fontSize: 18,
      lineHeight: '26px',
      color: theme.palette.text.desk,
    },
    [theme.breakpoints.sm]: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
      '& h1': {
        marginBottom: theme.spacing(2),
        fontSize: 64,
        lineHeight: '72px',
      },
      '& p': {
        maxWidth: 592,
        margin: '0 auto',
      },
    },
    [theme.breakpoints.xs]: {
      '& h1': {
        fontSize: 40,
        lineHeight: '48px',
      },
      '& p': {
        paddingLeft: theme.spacing(2.5),
        paddingRight: theme.spacing(2.5),
      },
    },
  },
  list: {
    marginBottom: theme.spacing(10),
    paddingLeft: 150,
    paddingRight: 113,
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    gridTemplateRows: '1fr 1fr',
    gap: '32px 64px',
    gridTemplateAreas: `". . ."
                        ". . ."`,
    [theme.breakpoints.sm]: {
      marginBottom: theme.spacing(6),
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      gridTemplateColumns: '1fr 1fr',
      gridTemplateRows: '1fr 1fr  1fr',
      gridTemplateAreas: `". ."
                          ". . "
                          ". . "`,
      '& #base_min': {
        order: -4,
      },
      '& #base_max': {
        order: -3,
      },
      '& #quote_min': {
        order: -2,
      },
      '& #quote_max': {
        order: -1,
      },
    },
    [theme.breakpoints.xs]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      gap: '24px',
      gridTemplateColumns: '1fr',
      gridTemplateRows: '1fr 1fr 1fr 1fr 1fr',
      gridTemplateAreas: `"."
                          "."
                          "."
                          "."
                          "."
                          "."`,
    },
  },
  item: {
    '& p': {
      marginBottom: theme.spacing(0.5),
      fontSize: 18,
      lineHeight: '26px',
      textTransform: 'uppercase',
    },
    '& span': {
      fontSize: 14,
      lineHeight: '22px',
      color: theme.palette.text.desk,
    },
  },
  marketsHeading: {
    marginBottom: theme.spacing(3),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  marketsText: {
    fontSize: 18,
    lineHeight: '26px',
    textTransform: 'uppercase',
    [theme.breakpoints.sm]: {
      display: 'none',
    },
  },
  inputSearchContainer: {
    border: '1px solid rgba(255, 255, 255, 0.6)',
    '& .MuiInputAdornment-positionStart': {
      marginRight: 0,
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#999999',
      borderWidth: 1,
    },
  },
  textField: {
    [theme.breakpoints.sm]: {
      width: '100%',
    },
  },
  inputSearch: {
    padding: '12.5px 14px!important',
    color: '#ffffff!important',
    [theme.breakpoints.xs]: {
      fontSize: '14px!important',
    },
  },
  tableContainer: {
    marginBottom: theme.spacing(6),
    paddingLeft: 150,
    paddingRight: 113,
    [theme.breakpoints.sm]: {
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5),
      marginBottom: theme.spacing(4),
    },
    [theme.breakpoints.sm]: {
      marginBottom: theme.spacing(3),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  table: {
    minHeight: 100,
    '& td, th': {
      borderBottomColor: '#2f2f2f',
    },
    [theme.breakpoints.sm]: {
      '& th': {
        padding: '6px 7px',
      },
    },
    [theme.breakpoints.xs]: {
      '& .MuiTableCell-head': {
        fontSize: '9px!important',
      },
      '& th, td': {
        padding: '6px',
      },
    },
  },
  thead: {
    '& .MuiTableCell-head': {
      backgroundColor: '#232323',
      color: theme.palette.text.desk,
      textTransform: 'uppercase',
      fontSize: 12,
      lineHeight: '18px',
      fontWeight: 'bold',
    },
    [theme.breakpoints.md]: {
      '& .MuiTableCell-sizeSmall': {
        padding: 6,
      },
    },
  },
  tbody: {
    '& td': {
      color: '#ffffff',
      fontSize: 14,
      lineHeight: '20px',
    },
    [theme.breakpoints.md]: {
      '& .MuiTableCell-sizeSmall': {
        padding: 6,
      },
    },
    [theme.breakpoints.xs]: {
      '& td': {
        fontSize: 10,
      },
    },
  },
  pairTitle: {
    paddingTop: 6,
    paddingBottom: 6,
    display: 'flex',
    alignItems: 'center',
    '& img': {
      marginRight: theme.spacing(1.5),
    },
    '& span': {
      fontSize: 14,
      lineHeight: '20px',
      fontWeight: 700,
    },
    [theme.breakpoints.xs]: {
      '& span': {
        fontSize: 10,
      },
    },
  },
  btnMore: {
    marginTop: theme.spacing(3),
    margin: '0 auto',
    display: 'block',
    fontSize: 12,
    border: `1px solid ${theme.palette.text.desk}`,
    color: '#ffffff',
  },
}));

const ITEMS_PER_PAGE = 20;

function Markets() {
  const classes = useStyles();
  const { isLargeScreen, isTabletOrMobile } = useDimension();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [lastItemIdx, setLastItemIdx] = useState(-1);
  const [tradingPairs, setTradingPairs] = useState<TradingPair[]>([]);
  const [search, setSearch] = useState('');

  const getPairText = useCallback(function (tradingPair: TradingPair) {
    return `${tradingPair.base}-${tradingPair.quote}`;
  }, []);

  const filteredItems = useMemo(
    function () {
      const pairs = tradingPairs.filter((pair) =>
        getPairText(pair).toLowerCase().includes(search.toLowerCase())
      );

      if (pairs.length <= ITEMS_PER_PAGE) {
        setLastItemIdx(pairs.length);
      }
      if (!search.length) {
        setLastItemIdx(
          pairs.length > ITEMS_PER_PAGE ? ITEMS_PER_PAGE : pairs.length
        );
      }

      return pairs;
    },
    [tradingPairs, search, getPairText]
  );

  const handleShowMore = useCallback(
    function () {
      if (lastItemIdx !== filteredItems.length) {
        if (filteredItems.length - lastItemIdx >= ITEMS_PER_PAGE) {
          setLastItemIdx(lastItemIdx + ITEMS_PER_PAGE);
        } else {
          setLastItemIdx(filteredItems.length);
        }
      }
    },
    [filteredItems, lastItemIdx]
  );

  const fetchTradingPairs = useCallback(async function () {
    setLoading(true);
    setError(false);
    try {
      const { data: result } = await Api.get('/get_markets_info');
      setTradingPairs(result.data);
      setLastItemIdx(
        result.data.length >= ITEMS_PER_PAGE
          ? ITEMS_PER_PAGE
          : result.data.length
      );
    } catch (error) {
      setError(true);
      console.error("[ERROR]: can't load trading pairs", error);
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    fetchTradingPairs();
  }, []);

  return (
    <main className={classes.container}>
      <Grow in timeout={2000}>
        <section className={classes.topPanel}>
          <h1>Markets</h1>
          <p>
            Assets and trading pairs available with key information for your
            trading.
          </p>
        </section>
      </Grow>
      <ContentBox maxWidth={isLargeScreen ? '1920px' : 'inherit'}>
        <Grow in timeout={2000}>
          <ul className={classes.list}>
            <li className={classes.item} id="base_min">
              <p>Base order minimum</p>
              <span>
                The minimum size for an order specified in the base currency.
                For example, in the BTC-USD book, orders specified in BTC must
                be greater than Base Order Minimum.
              </span>
            </li>
            <li className={classes.item} id="quote_min">
              <p>Quote order minimum</p>
              <span>
                The minimum size for an order specified in the quote. For
                example, in the BTC-USD book, orders specified in USD must be
                greater than Quote Order Minimum.
              </span>
            </li>
            <li className={classes.item}>
              <p>Lot Size</p>
              <span>
                Such limitations only allow order amounts that are a multiple of
                lot size. For example, if an order lot size is 0.5, then order
                amounts 1.5, 4, 10.5, are allowed, while amounts 0.3, 1.2, 10.9
                are not allowed.
              </span>
            </li>
            <li className={classes.item} id="base_max">
              <p>Base order maximum</p>
              <span>
                The maximum size for an order specified in the base currency.
                For example, in the BTC-USD book, orders specified in BTC must
                be less than Base Order Maximum.
              </span>
            </li>
            <li className={classes.item} id="quote_max">
              <p>Quote order maximum</p>
              <span>
                The maximum size for an order specified in the base currency.
                For example, in the BTC-USD book, orders specified in USD must
                be less than Quote Order Maximum.
              </span>
            </li>
            <li className={classes.item}>
              <p>Price Precision</p>
              <span>
                The maximum number of decimal places the price can have. For
                example, if price precision is 1, then prices 1234, 1234.5 are
                allowed, while 1234.56, 1234.567 are not allowed.
              </span>
            </li>
          </ul>
        </Grow>
      </ContentBox>
      <ContentBox
        maxWidth={isLargeScreen ? '1920px' : 'inherit'}
        className={classes.tableContainer}
      >
        <div className={classes.marketsHeading}>
          <p className={classes.marketsText}>All markets</p>
          <TextField
            variant="outlined"
            color="primary"
            className={classes.textField}
            placeholder={isTabletOrMobile ? 'Search market' : 'Search'}
            InputProps={{
              classes: {
                input: classes.inputSearch,
                root: classes.inputSearchContainer,
              },
              startAdornment: (
                <InputAdornment position="start">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.8424 17.684C12.3605 17.6837 13.8349 17.1755 15.0307 16.2403L18.7906 20L20 18.7907L16.2401 15.031C17.1758 13.8351 17.6844 12.3604 17.6847 10.842C17.6847 7.06949 14.6151 4 10.8424 4C7.06965 4 4 7.06949 4 10.842C4 14.6145 7.06965 17.684 10.8424 17.684ZM10.8424 5.7105C13.6725 5.7105 15.9741 8.01197 15.9741 10.842C15.9741 13.672 13.6725 15.9735 10.8424 15.9735C8.01219 15.9735 5.71059 13.672 5.71059 10.842C5.71059 8.01197 8.01219 5.7105 10.8424 5.7105Z"
                      fill="white"
                    />
                  </svg>
                </InputAdornment>
              ),
            }}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
        {!isTabletOrMobile ? (
          <Table stickyHeader size="small" className={classes.table}>
            <TableHead className={classes.thead}>
              <TableRow>
                <TableCell>Market</TableCell>
                <TableCell>Base</TableCell>
                <TableCell>Quote</TableCell>
                <TableCell>Base or. min.</TableCell>
                <TableCell>Base or. max.</TableCell>
                <TableCell>quote or. Min.</TableCell>
                <TableCell>quote or. MAX.</TableCell>
                <TableCell>Base Lot size</TableCell>
                <TableCell>Quote Lot size</TableCell>
                <TableCell>Price Precision</TableCell>
              </TableRow>
            </TableHead>
            <TableBody className={classes.tbody}>
              {filteredItems.length ? (
                filteredItems.slice(0, lastItemIdx).map((pair) => (
                  <TableRow key={`${pair.base}-${pair.quote}`}>
                    <TableCell>
                      <div className={classes.pairTitle}>
                        <CurrencyIcon title={pair.base} />
                        <HighlightText
                          color={search.length ? 'grey' : 'white'}
                          text={getPairText(pair)}
                          search={[search]}
                        />
                      </div>
                    </TableCell>
                    <TableCell>{pair.base}</TableCell>
                    <TableCell>{pair.quote}</TableCell>
                    <TableCell>{pair.baseMin}</TableCell>
                    <TableCell>{pair.baseMax}</TableCell>
                    <TableCell>{pair.quoteMin}</TableCell>
                    <TableCell>{pair.quoteMax}</TableCell>
                    <TableCell>
                      <Number precision={pair.basePrecision}>
                        {pair.baseLotSize}
                      </Number>
                    </TableCell>
                    <TableCell>
                      <Number precision={pair.quotePrecision}>
                        {pair.quoteLotSize}
                      </Number>
                    </TableCell>
                    <TableCell>{pair.pricePrecision}</TableCell>
                  </TableRow>
                ))
              ) : (
                <TablePlaceholder
                  text={error ? "Can't load pairs" : 'No records found'}
                  loading={loading}
                  iconSrc={NotFoundIcon}
                />
              )}
            </TableBody>
          </Table>
        ) : (
          <Table stickyHeader size="small" className={classes.table}>
            <TableHead className={classes.thead}>
              <TableRow>
                <TableCell>Market / Price precision</TableCell>
                <TableCell>Base or. Min / Max</TableCell>
                <TableCell>quote or. Min / Max</TableCell>
                <TableCell>Lot size Base / Quote</TableCell>
              </TableRow>
            </TableHead>
            <TableBody className={classes.tbody}>
              {filteredItems.length ? (
                filteredItems.slice(0, lastItemIdx).map((pair) => (
                  <TableRow key={`${pair.base}-${pair.quote}`}>
                    <TableCell>
                      <div className={classes.pairTitle}>
                        <CurrencyIcon title={pair.base} />
                        <div>
                          <HighlightText
                            color={search.length ? 'grey' : 'white'}
                            text={getPairText(pair)}
                            search={[search]}
                          />
                          <span>{pair.pricePrecision}</span>
                        </div>
                      </div>
                    </TableCell>
                    <TableCell>
                      <p>{pair.baseMin}</p>
                      <p>{pair.baseMax}</p>
                    </TableCell>
                    <TableCell>
                      <p>{pair.quoteMin}</p>
                      <p>{pair.quoteMax}</p>
                    </TableCell>
                    <TableCell>
                      <Number precision={pair.basePrecision}>
                        {pair.baseLotSize}
                      </Number>
                      <br />
                      <Number precision={pair.quotePrecision}>
                        {pair.quoteLotSize}
                      </Number>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TablePlaceholder
                  text={error ? "Can't load pairs" : 'No records found'}
                  loading={loading}
                  iconSrc={NotFoundIcon}
                />
              )}
            </TableBody>
          </Table>
        )}
        {lastItemIdx !== filteredItems.length ? (
          <Button className={classes.btnMore} onClick={handleShowMore}>
            Show more
          </Button>
        ) : (
          <></>
        )}
      </ContentBox>
    </main>
  );
}

export default Markets;
