import React from 'react';
import clsx from 'clsx';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Highlighter from 'react-highlight-words';
import Typography from '@material-ui/core/Typography';
import type { HighlighterProps } from 'react-highlight-words';
import type { TypographyProps } from '@material-ui/core/Typography';

type TextColors = 'white' | 'grey';

interface IProps {
  search: string[];
  text: string;
  color?: TextColors;
  typographyProps?: TypographyProps;
  highlighterProps?: HighlighterProps;
}

const useStyles = makeStyles((theme) => ({
  baseText: {},
  whiteText: {
    color: '#ffffff',
  },
  greyText: {
    color: '#666666',
  },
  highlightText: {
    background: '#2F2F2F',
    color: '#ffffff',
  },
}));

function HighlightText({
  typographyProps,
  highlighterProps,
  text,
  search,
  color,
}: IProps) {
  const classes = useStyles();

  return (
    <Typography
      {...typographyProps}
      className={clsx(
        classes.baseText,
        color === 'white' ? classes.whiteText : classes.greyText,
        typographyProps?.className
      )}
    >
      <Highlighter
        autoEscape
        {...highlighterProps}
        textToHighlight={text}
        searchWords={search}
        highlightClassName={clsx(
          classes.baseText,
          classes.highlightText,
          highlighterProps?.highlightClassName
        )}
      />
    </Typography>
  );
}

export default HighlightText;
