import React, { useState, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';

import Navigation from './Navigation';
import CloseIcon from '../icons/close.svg';
import Logo from '../Logo';
import Button from '../Button';
import HamburgerIcon from '../../static/images/toggle_header_icon.svg';
import { getObjectValues } from '../../helpers';
import { AppRoutes } from '../../Routes';
import { rem } from "../../theme/constants";

export const useStyles = makeStyles((theme: any) => ({
  header: {
    borderBottom: `${rem(1)} solid #2f2f2f`,
    backgroundColor: '#000000',
    position: 'sticky',
    top: 0,
    zIndex: 4,
  },
  wrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '0 auto',
    padding: '17.5px 28px',
    position: 'relative',
    '@media (min-width: 1090px) and (max-width: 1180px)': {
      paddingLeft: 16,
      paddingRight: 16,
    },
    [theme.breakpoints.sm]: {
      paddingLeft: 0,
      paddingRight: 0,
      paddingTop: 17,
      paddingBottom: 14,
      flexDirection: 'column',
    },
  },
  main: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.sm]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      width: '100%',
    },
  },
  logo: {
    [theme.breakpoints.sm]: {
      paddingLeft: 42,
      '& img': {
        width: 90,
      },
    },
    [theme.breakpoints.xs]: {
      paddingLeft: 26,
    },
  },
  actions: {
    [theme.breakpoints.sm]: {
      marginTop: 24,
    },
    [theme.breakpoints.xs]: {
      textAlign: 'center',
    },
  },
  actionBtn: {
    width: 120,
    height: 32,
    padding: '7px 16px 12px',
    cursor: 'pointer',
    [theme.breakpoints.sm]: {
      width: 256,
      height: 60,
      fontWeight: 'bold',
      fontSize: '18px',
      lineHeight: '26px',
      padding: '18px 23px 14px',
    },
    [theme.breakpoints.xs]: {
      width: 327,
    },
  },
  hidden: {
    [theme.breakpoints.sm]: {
      display: 'none',
    },
  },
  btnSignIn: {
    marginRight: 16,
    [theme.breakpoints.sm]: {
      marginRight: 32,
    },
    [theme.breakpoints.xs]: {
      marginRight: 0,
      marginBottom: 16,
    },
  },
  btnHamburger: {
    display: 'none',
    position: 'absolute',
    right: 42,
    border: 0,
    background: 'transparent',
    [theme.breakpoints.sm]: {
      display: 'block',
      top: 17,
    },
    [theme.breakpoints.xs]: {
      right: 26,
    },
  },
  overlay: {
    [theme.breakpoints.sm]: {
      position: 'fixed',
      overflowY: 'auto',
      height: '100vh',
      width: '100%',
      background: '#000000',
      top: 0,
      zIndex: 9,
    },
  },
  warningWrapper: {
    backgroundColor: '#000000',
    width: '100%',
    padding: '10px 16px',
    borderTop: `${rem(1)} solid #2f2f2f`,
  },
  warningMessage: {
    fontSize: '14px',
    lineHeight: '24px',
    fontWeight: 'bold',
    textAlign: 'center'
  },
  warningMessageLink: {
    textDecoration: 'underline',
    fontSize: '14px',
    lineHeight: '24px',
    fontWeight: 'bold',
    color: '#fff',
    transition: 'color 0.3s ease',

    '&:hover, &:focus': {
      color: '#2BCBD7'
    }
  }
}));

type MenuItem = {
  [key: string]: {
    link: string;
    title: string;
  };
};

type Menu = {
  auth?: MenuItem;
  companyLogo?: MenuItem;
  menu?: MenuItem[];
  error: string | null;
};

interface IProps {
  items: any;
  showWarning: boolean;
}

const { REACT_APP_STAKING_URL, REACT_APP_LIQUIDITY_URL } = process.env;

function Header({ items, showWarning }: IProps) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const { push, location } = useHistory();

  function handleCloseMenu() {
    setOpen(false);
  }

  function handleClickGetStarted() {
    handleCloseMenu();
    push(AppRoutes.contacts);
  }

  const getLoginUrl = useCallback(function (location: string) {
    return location === AppRoutes.staking
      ? REACT_APP_STAKING_URL
      : REACT_APP_LIQUIDITY_URL;
  }, []);

  return (
    <header className={classes.header}>
      <div className={clsx({ [classes.overlay]: open })}>
        <div className={classes.wrapper}>
          <div className={classes.main}>
            <Logo
              shortLogo
              className={classes.logo}
              onClick={() => setOpen(false)}
            />
            <Navigation
              onClose={handleCloseMenu}
              items={getObjectValues(items?.menu?.products?.columns)}
              open={open}
            />
          </div>
          <div className={clsx(classes.actions, { [classes.hidden]: !open })}>
            <Button
              component="a"
              href={getLoginUrl(location.pathname)}
              external
              sameWindow
              className={clsx(classes.actionBtn, classes.btnSignIn)}
            >
              Sign In
            </Button>
            <Button
              variant="colored"
              component="a"
              onClick={handleClickGetStarted}
              className={classes.actionBtn}
            >
              Contact us
            </Button>
          </div>
          <button
            className={classes.btnHamburger}
            onClick={() => setOpen((v) => !v)}
          >
            <img
              src={open ? CloseIcon : HamburgerIcon}
              alt="toggle-navigation"
            />
          </button>
        </div>
      </div>
      { showWarning &&
        <>
          <div className={classes.warningWrapper}>
            <p className={classes.warningMessage}>
              Incentivized promotional campaigns are currently unavailable for
              participation by U.K. users.{' '}
              <a href="https://cexio.uk/financial_promotions_regime" target="_blank" rel="noopener noreferrer" className={classes.warningMessageLink}>
                Please click here for more information
              </a>
              .

            </p>
          </div>
          <div className={classes.warningWrapper}>
            <p className={classes.warningMessage}>
              Don&apos;t invest unless you&apos;re prepared to lose all the money you invest. This is a high-risk investment
              and you are unlikely to be protected if something goes wrong.{' '}
              <a href="https://cexio.uk/risk_summary" target="_blank" rel="noopener noreferrer" className={classes.warningMessageLink}>
                Take 2 minutes to learn more
              </a>
              .
            </p>
          </div>
        </>
      }
    </header>
  );
}

export default Header;
