import React, { useState, useEffect, useRef } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Form } from 'react-final-form';
import IMask from 'imask';

import Input from '../../components/Form/Input';
import Select from '../../components/Form/Select';
import Button from '../../components/Button';
import ResultPopup from './Popup';
import useGlobalStyles from '../../theme/useGlobalStyles';
import { useContactsRequest } from '../../api/contacts';
import {
  required,
  maxLength,
  phoneLength,
  isEmail,
  isWord,
  composeValidators,
} from '../../validators';

const useStyles = makeStyles((theme: any) => ({
  root: {
    paddingTop: 100,
    paddingBottom: 150,
    color: theme.palette.text.grey,
    background: theme.palette.background.secondary,
    [theme.breakpoints.sm]: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(6),
    },
  },
  form: {
    width: 604,
    margin: '0 auto',
    [theme.breakpoints.xs]: {
      width: '100%',
    },
  },
  doubleRow: {
    display: 'flex',
    justifyContent: 'space-between',
    '& > *': {
      flex: '0 0 290px',
    },
    [theme.breakpoints.xs]: {
      flexDirection: 'column',
      '& > *': {
        flex: '0 0 auto',
      },
    },
  },
  description: {
    textAlign: 'center',
    marginTop: 8,
    fontSize: 14,
    lineHeight: '20px',
    '& a': {
      color: '#1BB6C1',
    },
    [theme.breakpoints.xs]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  button: {
    margin: '32px auto 0',
    paddingLeft: '93px !important',
    paddingRight: '93px !important',
    display: 'block',
    [theme.breakpoints.xs]: {
      width: '100%',
    },
    [theme.breakpoints.xs]: {
      marginTop: theme.spacing(3),
      paddingTop: '17px!important',
      paddingBottom: '17px!important',
    },
  },
}));

export default function ContactsForm() {
  const globalClasses = useGlobalStyles();
  const classes = useStyles();
  const [result, setResult] = useState<string | undefined>();
  const [send, sending] = useContactsRequest((res: any) => {
    setResult(res && res.ok && res.ok === 'ok' ? 'success' : 'error');
  });
  const phoneField = useRef(null);

  useEffect(() => {
    if (phoneField.current) {
      // @ts-ignore
      IMask(phoneField.current, { mask: '+000000000000000' });
    }
  }, [phoneField.current]);

  return (
    <div className={clsx(globalClasses.container, classes.root)}>
      <Form
        onSubmit={(values) => {
          // @ts-ignore
          send({
            ...values,
            interested_in: values.interested_in.value,
            hear_about_us: values.hear_about_us.value,
          });
        }}
        initialValues={{
          firstname: '',
          lastname: '',
          email: '',
          phone: '',
          company: '',
          message: '',
          interested_in: '',
          hear_about_us: '',
        }}
        render={({ handleSubmit, form }) => (
          <form onSubmit={handleSubmit} className={classes.form}>
            {result && (
              <ResultPopup
                goToRoot
                type={result}
                onClose={() => {
                  // @ts-ignore
                  form.restart();
                  setResult(undefined);
                }}
              />
            )}
            <div className={classes.doubleRow}>
              <Input
                name="firstname"
                label="First name"
                placeholder="Enter your first name"
                required
                validate={composeValidators(
                  (v: any) => required(v, 'First name '),
                  (v: any) => maxLength(v, 64, 'First name '),
                  (v: any) => isWord(v, 'First name ')
                )}
              />
              <Input
                name="lastname"
                label="Last name"
                placeholder="Enter your last name"
                required
                validate={composeValidators(
                  (v: any) => required(v, 'Last name '),
                  (v: any) => maxLength(v, 64, 'Last name '),
                  (v: any) => isWord(v, 'Last name ')
                )}
              />
            </div>
            <Input
              name="email"
              label="Email address"
              placeholder="Enter your email"
              required
              validate={composeValidators(
                (v: any) => required(v, 'Email '),
                isEmail,
                (v: string) => maxLength(v, 128, 'Email ')
              )}
            />
            <div className={classes.doubleRow}>
              <Input
                name="phone"
                label="Phone number"
                required
                placeholder="Enter your number"
                type="text"
                inputRef={phoneField}
                validate={composeValidators(
                  (v: any) => required(v, 'Phone number '),
                  (v: string) => phoneLength(v)
                )}
              />
              <Input
                name="company"
                label="Company name"
                placeholder="Enter company name"
                validate={(v: string) => maxLength(v, 128, 'Company name ')}
              />
            </div>
            <div className={classes.doubleRow}>
              <Select
                required
                name="interested_in"
                label="I'm interested"
                placeholder="Please select"
                validate={(v: { label: string, value: string }) => required(v.value, 'Field ')}
                options={[
                  { value: 'Prime Liquidity', label: 'Prime Liquidity' },
                  { value: 'Prime Staking', label: 'Prime Staking' },
                ]}
              />
              <Select
                name="hear_about_us"
                label="How did you hear about us?"
                placeholder="Please select"
                options={[
                  { value: 'Customer referral', label: 'Customer referral' },
                  { value: 'Event', label: 'Event' },
                  {
                    value: 'Google or Bing search',
                    label: 'Google or Bing search',
                  },
                  { value: 'Online advertising', label: 'Online advertising' },
                  { value: 'Word of mouth', label: 'Word of mouth' },
                ]}
              />
            </div>
            <Input
              name="message"
              label="Your message"
              placeholder="Enter your message"
              textarea
              required
              validate={composeValidators(
                (v: any) => required(v, 'Your message '),
                (v: any) => maxLength(v, 2048, 'Your message ')
              )}
            />
            <p className={classes.description}>
              Your personal data will be processed in accordance with{' '}
              <a
                href="https://cex.io/privacy"
                rel="noopener noreferrer"
                target="_blank"
              >
                CEX.IO policy
              </a>
            </p>
            <Button
              type="submit"
              size="large"
              variant="colored"
              className={classes.button}
              // @ts-ignore
              disabled={sending}
            >
              Submit
            </Button>
          </form>
        )}
      />
    </div>
  );
}
