import palette from '../palette';

const overrides = {
  root: {
    borderRadius: 0,
    color: '#ffffff',
    '&:not(.Mui-disabled)': {
      '&:hover, &.Mui-focused': {
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: '#ffffff',
          borderWidth: 1,
        },
      },
    },
    '& > .MuiInputAdornment-root *': {
      color: '#ffffff',
      fontSize: 14,
      lineHeight: '24px',
      fontWeight: 700,
    },
    '&.Mui-disabled': {
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: palette.border.gray,
        '&:hover': {
          borderColor: palette.text.red + '!important',
        },
      },
    },
  },
  input: {
    padding: '13px',
    '&::placeholder': {
      fontSize: 14,
      color: palette.text.desk,
      opacity: 1,
    },
  },
  notchedOutline: {
    borderColor: palette.border.gray,
  },
};

export default overrides;
