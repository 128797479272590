import React, { useEffect } from 'react';
import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook';
import { Redirect, Route, Switch, useHistory, useLocation } from 'react-router-dom';

import ContactsPage from './views/Contacts';
import MainPage from './views/Main';
import SolutionsPage from './views/Solutions';
import FeaturesPage from './views/Features';
import MarketsPage from './views/Markets';
import AboutPage from './views/About';
import StakingPage from './views/Staking';
import NotFoundPage from './views/NotFound';

export enum AppRoutes {
  root = '/',
  contacts = '/contacts',
  solutions = '/solutions',
  features = '/features',
  markets = '/markets',
  about = '/about',
  staking = '/staking'
}

function Routes() {
  const sendDataToGTM = useGTMDispatch();
  const { pathname } = useLocation();
  const { listen } = useHistory();

  useEffect(() => {
    const unListen = listen(() => {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'auto',
      });
    });
    return () => {
      unListen();
    };
  }, []);

  useEffect(() => {
    setTimeout(() => {
      sendDataToGTM({
        event: 'view_gtm',
        type_event: 'view',
        // TODO provide user auth data
        // user_id: ==> user id,
        // sign_in: ==> yes ? 1 : 0,
      });
    }, 100);
  }, [pathname, sendDataToGTM]);

  return (
    <Switch>
      <Route path={AppRoutes.root} component={MainPage} exact />
      <Route path={AppRoutes.contacts} component={ContactsPage} exact />
      <Route path={AppRoutes.solutions} component={SolutionsPage} exact />
      <Route path={AppRoutes.features} component={FeaturesPage} exact />
      <Route path={AppRoutes.markets} component={MarketsPage} exact />
      <Route path={AppRoutes.about} component={AboutPage} exact />
      <Route path={AppRoutes.staking} component={StakingPage} exact />
      <Route path="*">
        <NotFoundPage />
      </Route>
    </Switch>
  );
}

export default Routes;
