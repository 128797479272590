import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import type { LinkItem } from './Navigation';
import { getHeaderIcon } from '../../api/header';

export const useStyles = makeStyles((theme: any) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: 230,
    marginBottom: 22,
    [theme.breakpoints.sm]: {
      width: 'auto',
    },
  },
  rowContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    '& button': {
      marginRight: 8,
    },
  },
  top: {
    display: 'flex',
    alignItems: 'center',
    width: 'max-content',
    background: 'none',
    border: 0,
    cursor: 'pointer',
    '&:hover': {
      '& path': {
        fill: '#2bcbd2',
      },
      '& span': {
        color: '#2BCBD7',
      },
    },
  },
  icon: {
    marginRight: 12,
    fontSize: 10,
    '& path': {
      fill: '#ffffff',
    },
  },
  title: {
    display: 'block',
    textDecoration: 'none',
    color: '#ffffff',
    fontWeight: 600,
    fontSize: 14,
    lineHeight: '20px',
  },
  desc: {
    marginLeft: 35,
    fontSize: 12,
    fontWeight: 400,
    lineHeight: '17px',
    color: '#999999',
    userSelect: 'text',
  },
  prime: {
    cursor: 'not-allowed!important',
    '& path': {
      fill: '#2bcbd2',
    },
    '& span': {
      color: '#2BCBD7',
    },
  },
  badge: {
    width: 'max-content',
    padding: '4px 6px',
    fontSize: 8,
    fontWeight: 'bold',
    borderRadius: 2,
    color: '#ffffff',
    background: 'rgba(255, 255, 255, 0.2)',
  },
  notAllowed: {
    cursor: 'not-allowed',
    '& span': {
      color: '#999999',
    },
    '& path': {
      fill: '#999999',
    },
    '&:hover': {
      '& path': {
        fill: '#999999',
      },
      '& span': {
        color: '#999999',
      },
    },
  },
}));

interface IProps {
  product: LinkItem;
  isPrime: boolean;
  className?: string; 
}

function Product({ product, isPrime, className }: IProps) {
  const classes = useStyles();
  const [icon, setIcon] = useState<string>('');

  useEffect(() => {
    (async () => {
      const iconContent = await getHeaderIcon(product.icon);
      setIcon(iconContent);
    })();
  }, [product.icon]);

  function handleProductClick(url: string = '') {
    if (url) {
      window.open(url, '_blank');
    }
  }

  return (
    <div
      className={clsx(classes.container, {
        [classes.rowContainer]: !product?.link,
      }, className)}
    >
      {isPrime ? (
        <button className={clsx(classes.top, classes.prime)} disabled>
          <div
            dangerouslySetInnerHTML={{ __html: icon }}
            className={clsx(classes.icon, 'product-icon')}
          />
          <span className={clsx(classes.title, 'product-title')}>
            {product.title}
          </span>
        </button>
      ) : (
        <button
          className={clsx(classes.top, {
            [classes.notAllowed]: !product?.link,
          })}
          onClick={() => handleProductClick(product?.link)}
        >
          <div
            dangerouslySetInnerHTML={{ __html: icon }}
            className={clsx(classes.icon, 'product-icon')}
          />
          <span className={clsx(classes.title, 'product-title')}>
            {product.title}
          </span>
        </button>
      )}
      {product?.link ? (
        <span className={classes.desc}>{product.desc}</span>
      ) : (
        <span className={classes.badge}>{product.badge}</span>
      )}
    </div>
  );
}

export default Product;
