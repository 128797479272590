import Api, { useUpdateApi } from './index';
import type { StakingRequest } from '../typings';

export function useStakingRequest(cb: any) {
  return useUpdateApi(
    async (data: StakingRequest) =>
      Api.post('/do_submit_staking_request', data),
    cb
  );
}
