import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

import Header from '../Header';
import Footer from './Footer';
import { AppRoutes } from '../../Routes';
import { getUserProfileIsoCountry } from '../../api';

interface IProps {
  children: any;
  headerData: any;
}

const useStyles = makeStyles((theme: any) => ({
  layoutContainer: {
    minHeight: '100vh',
    color: theme.palette.text.white,
    fontFamily: 'Helvetica, sans-serif',
    fontWeight: 400,
    '&.menuOpen': {
      overflow: 'hidden',
    },
  },
}));

export default function Layout({ children, headerData }: IProps) {
  const classes = useStyles();
  const { pathname } = useLocation();
  const existsRoutes = Object.values(AppRoutes) as string[];
  const [showUkWarning, setShowUkWarning] = useState(false)

  const resolveIsNeedToShowWarning = async () => {
    const residenceCountryCookie = document.cookie.split(';').find((c) => c.trim().startsWith('residenceCountry='))
    const residenceCountryCookieValue = residenceCountryCookie?.split('=')[1]

    if (residenceCountryCookieValue === 'GB') {
      return setShowUkWarning(true)
    } else {
      const userCountryFromContactCenter = await getUserProfileIsoCountry()

      if (userCountryFromContactCenter === 'GB') {
        return setShowUkWarning(true)
      }
    }

    return setShowUkWarning(false)
  }

  useEffect(() => {
    resolveIsNeedToShowWarning().catch((error) => console.error('', error))
  }, [])

  return (
    <div className={classes.layoutContainer}>
      <Header
        items={headerData}
        showWarning={showUkWarning}
      />
      {children}
      {existsRoutes.includes(pathname) && <Footer />}
    </div>
  );
}
