import Big from 'big.js';

Big.NE = -12;

export function bigFromStr(v?: string | number): Big {
  if (!v) return Big(0);
  try {
    return Big(v.toString().replace(',', '.'));
  } catch {
    return Big(0);
  }
}
