import React, { Suspense, useEffect, StrictMode } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { GTMProvider } from '@elgorditosalsero/react-gtm-hook';
import { ThemeProvider } from '@material-ui/core/styles';

import { useHeaderRequest } from './api/header'
import Layout from './components/Layout';
import Routes from './Routes';

import './App.css';
import theme from './theme';

const { REACT_APP_GTM_CODE } = process.env;

function App() {
  const gtmParams = { id: REACT_APP_GTM_CODE };
  const [headerItems = [],, loadHeaderItems] = useHeaderRequest()

  useEffect(() => {
    loadHeaderItems()
  }, [])

  return (
    <GTMProvider state={gtmParams}>
    <Suspense fallback="Loading...">
      <ThemeProvider theme={theme}>
        <BrowserRouter>
        <StrictMode>
          <Layout headerData={headerItems}>
            <Routes />
          </Layout>
          </StrictMode>
        </BrowserRouter>
      </ThemeProvider>
    </Suspense>
    </GTMProvider>
  );
}

export default App;
