export function getObjectValues(obj: object): any[] {
    return Object.values(obj ?? [])
}

export function getCurrentUTCDate() {
    const date = new Date();
    const isoDate = date.toISOString();

    return `${isoDate.substr(0, 10)} ${isoDate.substr(11, 8)}`;
}
