import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Button from '../../components/Button';
import PlusIcon from '../../components/UI/PlusIcon';

const useStyles = makeStyles((theme: any) => ({
  root: {
    position: 'fixed',
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'rgba(0,0,0,0.8)',
    backdropFilter: 'blur(4px)',
    zIndex: 10000,
  },
  panel: {
    width: 512,
    padding: '24px 26px',
    textAlign: 'center',
    position: 'relative',
    background: '#161616',
    color: theme.palette.text.white,
    [theme.breakpoints.xs]: {
      width: 'auto',
      marginLeft: 16,
      marginRight: 16,
    },
  },
  icon: {
    background: '#222',
    border: '1px solid #fff',
    width: 64,
    height: 64,
    margin: '0 auto',
    borderRadius: 35,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    marginTop: 32,
    fontSize: 18,
    lineHeight: '20px',
    fontWeight: 700,
  },
  text: {
    fontSize: 16,
    lineHeight: '22px',
    marginTop: 16,
  },
  button: {
    margin: '24px auto 0',
    display: 'inline-block',
    height: 48,
    padding: '15px 66px !important',
    lineHeight: '18px',
  },
  close: {
    position: 'absolute',
    right: 26,
    top: 24,
    width: 30,
    height: 30,
    transform: 'rotate(45deg)',
    cursor: 'pointer',
  },
}));

interface IProps {
  type: string;
  onClose: any;
  goToRoot?: boolean;
}

export default function ResultPopup({ type, goToRoot, onClose }: IProps) {
  const classes = useStyles();

  useEffect(() => {
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  return (
    <div className={classes.root}>
      <div className={classes.panel}>
        <div className={classes.icon}>
          {type === 'success' ? (
            <svg
              width="29"
              height="21"
              viewBox="0 0 29 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.05566 8.38549L11.2005 18.8287L28.2337 1.29443"
                stroke="white"
                strokeWidth="2"
              />
            </svg>
          ) : (
            <svg
              width="29"
              height="29"
              viewBox="0 0 29 29"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M27.8496 1L0.849609 28M0.849609 1L27.8496 28"
                stroke="white"
                strokeWidth="2"
              />
            </svg>
          )}
        </div>
        <div className={classes.title}>
          {type === 'success' ? 'Thank you' : 'Something went wrong'}
        </div>
        <div className={classes.text}>
          {type === 'success'
            ? 'Your message has been received and we will be contacting you shortly to follow-up.'
            : 'Unable to submit form, please try again later'}
        </div>
        <Button
          component={goToRoot ? 'a' : 'button'}
          href="/"
          onClick={() => {
            if (!goToRoot) {
              onClose();
            }
          }}
          className={classes.button}
          variant="colored"
        >
          Go to Cex.io Prime
        </Button>
        <span className={classes.close} onClick={onClose}>
          <PlusIcon />
        </span>
      </div>
    </div>
  );
}
