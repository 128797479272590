import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { bigFromStr } from '../../helpers/numbers';

const useStyles = makeStyles((theme: any) => ({
  root: {},
  gray: {
    color: '#666',
  },
}));

interface IProps {
  children?: number | string | null;
  precision?: number;
  notGray?: boolean;
  roundType?: 0 | 1 | 2 | 3;
  emptyValueType?: '—' | 'N/A';
}

export default function NumberComponent({
  children,
  precision = 8,
  notGray = false,
  roundType = 1,
  emptyValueType = '—',
}: IProps) {
  const classes = useStyles();
  if (children === undefined || children === null) return <>{emptyValueType}</>;
  const numberInBig = bigFromStr(children);

  // adjust roundtype for negative numbers
  let roundTypeAdjusted = roundType;
  if (numberInBig.lt(0)) {
    if (roundType === 0) roundTypeAdjusted = 3;
    if (roundType === 3) roundTypeAdjusted = 0;
    if (roundType === 1) roundTypeAdjusted = 2;
    if (roundType === 2) roundTypeAdjusted = 1;
  }

  const rounded = numberInBig
    .toFixed(Number(precision), roundTypeAdjusted)
    .toString();
  const splittedRounded = rounded.split('.');
  const ceils = Number(splittedRounded[0]).toLocaleString('en-US');
  const decimals = splittedRounded[1] ? splittedRounded[1].split('') : [];
  let grayD = '';
  let whiteD = decimals[decimals.length - 1] === '0' ? '' : decimals.join('');
  if (!whiteD.length) {
    for (let i = decimals.length - 1; i >= 0; i--) {
      const char = decimals[i];
      if (!whiteD.length && char === '0') {
        grayD = char + grayD;
      } else {
        whiteD = char + whiteD;
      }
    }
  }
  return (
    <>
      {ceils}
      {whiteD.length || grayD.length ? '.' : ''}
      {whiteD}
      {grayD.length > 0 && (
        <span className={!notGray ? classes.gray : ''}>{grayD}</span>
      )}
    </>
  );
}
