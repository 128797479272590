import React, { useState } from 'react';
import clsx from 'clsx';
import { makeStyles, darken } from '@material-ui/core/styles';
import { Typography, Button } from '@material-ui/core';
import CurrencyIcon from '../UI/CurrencyIcon';

const useStyles = makeStyles((theme: any) => ({
  container: {
    padding: 24,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: '#232323',
    '&:hover': {
      cursor: 'pointer',
      background: darken('#232323', 0.1),
    },
  },
  information: {
    display: 'flex',
    alignItems: 'center',
  },
  image: {
    width: 44,
    height: 44,
    marginRight: theme.spacing(2),
  },
  fullName: {
    fontSize: 24,
    lineHeight: '32px',
  },
  signature: {
    fontSize: 18,
    lineHeight: '26px',
    color: 'rgba(255, 255, 255, 0.6)',
  },
  apy: {
    fontSize: 32,
    fontWeight: 700,
    lineHeight: '44px',
    color: theme.palette.text.green,
  },
  btnStake: {
    fontSize: 16,
    fontWeight: 700,
    lineHeight: '24px',
    color: theme.palette.link.main,
  },
}));

interface IProps {
  className?: string;
  onClick?: Function;
  currency: string;
  name: string;
  stackingAPY: string;
}

function StakingAssetCard(props: IProps) {
  const { className, onClick, currency, name, stackingAPY } = props;
  const classes = useStyles();
  const [hovered, setHovered] = useState(false);

  return (
    <div
      className={clsx(classes.container, className)}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onClick={typeof onClick === 'function' ? () => onClick() : undefined}
    >
      <div className={classes.information}>
        <CurrencyIcon title={currency} className={classes.image} />
        <div>
          <Typography className={classes.fullName}>{name}</Typography>
          <Typography className={classes.signature}>{currency}</Typography>
        </div>
      </div>
      {hovered ? (
        <Button variant="text" className={classes.btnStake}>Stake</Button>
      ) : (
        <Typography className={classes.apy}>{stackingAPY}%</Typography>
      )}
    </div>
  );
}

export default StakingAssetCard;
