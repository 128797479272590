import React from 'react';
import clsx from 'clsx';
import { Slide } from '@material-ui/core';
import { useStyles } from './';
import type { IChildProps } from './';

function VerticalSection({
  image,
  title,
  text,
  mainAxis = 'row',
  icon,
  imageNode,
  className,
  children,
  background,
  isMobile,
  isTabletOrMobile,
  rootRef,
  animate,
}: IChildProps) {
  const classes = useStyles({
    background,
    mainAxis,
    isTabletOrMobile,
    isMobile,
  });

  return (
    <Slide in={animate} direction="up" timeout={1000}>
      <section className={clsx(classes.root, className)} ref={rootRef}>

        <div className={clsx(classes.content, 'section-content')}>
          {icon && <div className={classes.icon}>{icon}</div>}
          <h6 className={clsx(classes.title, 'section-title')}>
            {title.toUpperCase()}
          </h6>
          <p
            className={clsx(classes.text, 'section-text')}
            dangerouslySetInnerHTML={{ __html: text }}
          />
          {children}
        </div>
        {image ? (
          <img
            src={image}
            alt={`${image}'s_image`}
            className={clsx(classes.image, 'section-image')}
            style={{
              order: 1,
            }}
          />
        ) : (
          imageNode
        )}
      </section>
    </Slide>
  );
}

export default VerticalSection;
