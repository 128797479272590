import { useMediaQuery } from 'react-responsive';

export default function useDimension() {
  const isTabletOrMobile = useMediaQuery({
    query: '(max-width: 1127px)',
  });
  const isMobile = useMediaQuery({
    query: '(max-width: 480px)',
  });
  const isLargeScreen = useMediaQuery({
    query: '(min-width: 1920px)',
  });
  const isTablet = useMediaQuery({
    minWidth: 481,
    maxWidth: 1127,
  });

  const onlyMedium = useMediaQuery({
    minWidth: 1127,
    maxWidth: 1440,
  });

  return {
    isMobile,
    isTablet,
    isTabletOrMobile,
    isLargeScreen,
    onlyMedium,
  };
}
