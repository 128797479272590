import React from 'react';
import clsx from 'clsx';
import { isChrome } from 'react-device-detect';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import Loader from '../Loader';

const useStyles = makeStyles((theme) => ({
  row: {
    position: 'sticky',
    minHeight: 71,
  },
  chromeRow: {
    position: 'relative',
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  tollRow: {
    height: 240,
  },
  loading: {
    height: 150,
    '& .MuiTableCell-root': {
      paddingTop: theme.spacing(7),
    },
  },
  cell: {
    position: 'absolute',
    left: '50%',
    transform: 'translate(-50%, 0)',
    borderBottom: 0,
  },
  content: {
    marginTop: theme.spacing(2.5),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  icon: {
    marginLeft: theme.spacing(-2),
    marginBottom: theme.spacing(1),
    width: 55,
    // @ts-ignore
    [theme.breakpoints.xs]: {
      marginLeft: theme.spacing(-1),
      width: 35,
    },
  },
  text: {
    color: '#999999',
    fontSize: 14,
  },
  boldText: {
    fontSize: 18,
    lineHeight: '26px',
    fontWeight: 700,
    color: '#ffffff',
  },
}));

interface IProps {
  text: string;
  loading?: boolean;
  iconSrc?: string;
  className?: string;
  bold?: boolean;
}

function TablePlaceholder({ text, loading, iconSrc, className, bold }: IProps) {
  const classes = useStyles();

  return (
    <TableRow
      className={clsx(
        classes.row,
        {
          [classes.loading]: loading,
          [classes.tollRow]: !!iconSrc,
          [classes.chromeRow]: isChrome,
        },
        className
      )}
    >
      <TableCell className={classes.cell}>
        {loading ? (
          <Loader loading={loading} />
        ) : (
          <div className={classes.content}>
            {iconSrc && (
              <img
                src={iconSrc}
                alt={`${iconSrc}'s_icon`}
                className={classes.icon}
              />
            )}
            <Typography
              variant="body1"
              className={clsx(classes.text, { [classes.boldText]: bold })}
            >
              {text}
            </Typography>
          </div>
        )}
      </TableCell>
    </TableRow>
  );
}

export default TablePlaceholder;
