import React from 'react';
import { Link } from 'react-router-dom';
import LogoIcon from '../icons/prime-logo.svg';
import CexIcon from '../../static/images/header_cex_icon.svg';

interface IProps {
  className?: string;
  onClick?: () => void;
  shortLogo?: boolean;
}

function Logo({ className, onClick, shortLogo }: IProps) {
  return (
    <Link to="/" className={className} onClick={onClick}>
      <img src={shortLogo? CexIcon : LogoIcon} alt="cex_logo" />
    </Link>
  );
}

export default Logo;
