import React from 'react';
import clsx from 'clsx';
import { Slide, Grow } from '@material-ui/core';
import { useStyles } from './';
import type { IChildProps } from './';

function HorizontalSection({
  image,
  title,
  text,
  mainAxis = 'row',
  icon,
  imageNode,
  className,
  children,
  imageSide = 'left',
  background,
  isMobile,
  isTabletOrMobile,
  rootRef,
  animate,
}: IChildProps) {
  const classes = useStyles({
    background,
    mainAxis,
    isTabletOrMobile,
    isMobile,
    imageSide,
  });

  return (
    <section className={clsx(classes.root, className)} ref={rootRef}>
      <Slide
        in={animate}
        timeout={1000}
        direction={imageSide !== 'left' ? 'left' : 'right'}
      >
        {image ? (
          <img
            src={image}
            alt={`${image}'s_image`}
            className={clsx(classes.image, 'section-image')}
            style={{
              order: imageSide === 'left' && !isTabletOrMobile ? 0 : 1,
            }}
          />
        ) : (
          imageNode
        )}
      </Slide>
      <Grow in={animate} timeout={1000}>
        <div className={clsx(classes.content, 'section-content')}>
          {icon && <div className={classes.icon}>{icon}</div>}
          <h6 className={clsx(classes.title, 'section-title')} dangerouslySetInnerHTML={{ __html: title.toUpperCase() }} />
          <p
            className={clsx(classes.text, 'section-text')}
            dangerouslySetInnerHTML={{ __html: text }}
          />
          {children}
        </div>
      </Grow>
    </section>
  );
}

export default HorizontalSection;
