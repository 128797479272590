import React, { useEffect } from 'react';
import Big from 'big.js';
import clsx from 'clsx';
import {
  IconButton,
  InputAdornment,
  makeStyles,
  Modal,
  Typography,
  Button,
  ButtonGroup,
  MenuItem,
  Select,
} from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { AssetType } from './hooks/useGetStakingAssets';
import InputOutlined from '../../components/Form/InputOutlined';
import CurrencyIcon from '../../components/UI/CurrencyIcon';
import type { StakingRequest } from '../../typings';

interface IProps {
  preselectedAsset: AssetType;
  open: boolean;
  availableAssets: AssetType[];
  preselectedAmount: string;
  submitting: boolean;
  handleClose: () => void;
  handleRequest: (params: StakingRequest) => void;
  result?: string;
}

const schema = yup
  .object({
    period: yup.number().integer().required('Period is required field'),
    fullName: yup.string().required('Full Name is required field'),
    reward: yup.string(),
    companyName: yup.string().required('Company Name is required field'),
    email: yup.string().email().required('Email is required field'),
    phoneNumber: yup.string().required('Phone Number is required field'),
    currency: yup.string().required(),
    amount: yup
      .number()
      .typeError('Amount is required field')
      .required('Amount is required field'),
    message: yup.string(),
  })
  .required();

type FormData = yup.InferType<typeof schema>;

const useStyles = makeStyles((theme: any) => ({
  container: {
    maxWidth: 512,
    height: 624,
    overflowY: 'auto',
    marginTop: 79,
    margin: '0 auto',
    padding: '21px 26px 0px 26px',
    backgroundColor: theme.palette.background.light,
    outline: 0,
  },
  heading: {
    position: 'relative',
    textAlign: 'center',
  },
  currencyBox: {
    marginBottom: theme.spacing(2),
  },
  btnClose: {
    padding: 0,
    content: "' '",
    position: 'absolute',
    right: 0,
    top: 0,
    color: '#ffffff',
  },
  title: {
    color: '#ffffff',
    fontSize: 18,
    lineHeight: '26px',
    fontWeight: 700,
  },
  btnPercentage: {
    height: 24,
    border: 0,
    backgroundColor: '#232323',
    fontSize: 12,
    fontWeight: 400,
    lineHeight: '18px',
    textTransform: 'initial',
  },
  selected: {
    backgroundColor: '#ffffff',
    color: '#000000',
  },
  btnsContainer: {
    marginTop: theme.spacing(1),
    '& button:not(:last-child)': {
      marginRight: theme.spacing(1),
    },
  },
  list: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  listItem: {
    marginBottom: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  grid: {
    marginBottom: theme.spacing(3),
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: '24px',
  },
  field: {
    marginBottom: theme.spacing(3),
  },
  btnCancel: {
    backgroundColor: 'transparent',
    border: `1px solid ${theme.palette.border.gray}`,
  },
  btns: {
    '& button': {
      padding: '12.5px 15px',
    },
  },
  currencyTitle: {
    marginBottom: theme.spacing(1),
    fontSize: 12,
    lineHeight: '18px',
  },
  currencySelect: {
    minHeight: 45,
    '& .MuiSelect-outlined': {
      padding: '9.5px 16px',
    },
    '& .MuiSelect-selectMenu': {
      backgroundColor: 'transparent',
    },
  },
  menuItemContainer: {
    '&:hover': {
      backgroundColor: theme.palette.background.light + '!important',
    },
    '&:not(:last-child)': {
      borderBottom: `1px solid rgba(255,255,255, 0.2)`,
    },
    '&:not(:first-child)': {
      paddingTop: 8,
    },
  },
  menuItem: {
    marginRight: 44,
    display: 'flex',
    '& img': {
      marginRight: theme.spacing(1),
    },
    '& p, span': {
      marginTop: 1,
      fontSize: 14,
      lineHeight: '20px',
      color: '#ffffff',
    },
    '& span': {
      marginLeft: 4,
      color: '#999999',
    },
  },
  numberInput: {
    marginBottom: theme.spacing(2),
    '& > input[type="number"]': {
      '-moz-appearance': 'textfield',
    },
    '& > input::-webkit-outer-spin-button, input::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
    },
  },
}));

function RequestStakingModal({
  preselectedAsset,
  open,
  handleClose,
  availableAssets,
  handleRequest,
  result,
  submitting,
  preselectedAmount,
}: IProps) {
  const classes = useStyles();
  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    reset,
    watch,
    formState: { errors },
  } = useForm<FormData>({
    resolver: yupResolver(schema),
    defaultValues: {
      currency: preselectedAsset.currency,
      period: 365,
      amount: preselectedAmount ? +preselectedAmount : undefined,
      companyName: '',
      email: '',
      fullName: '',
      message: '',
      phoneNumber: '',
    },
  });

  useEffect(() => {
    if (result === 'success') {
      reset();
      handleClose();
    }
  }, [result]);

  useEffect(() => {
    if (preselectedAsset) {
      setValue('currency', preselectedAsset.currency);
    }
  }, [preselectedAsset]);

  function calculateEstimatedReward(
    amount: number,
    period: number,
    currency: string
  ) {
    const _currency = availableAssets.find((a) => a.currency === currency);
    if (_currency) {
      const res = Big(_currency.stackingAPY)
        .div(100)
        .mul(
          Big(
            Big(period || 0)
              .div(365)
              .mul(amount || 0)
          ).round(_currency.uiPrecision, Big.roundDown)
        )
        .toString();
      setValue('reward', res || '-');
    }
  }

  function onSubmit(formData: FormData) {
    handleRequest({
      type: 'staking',
      firstname: formData.fullName,
      email: formData.email,
      period: formData.period.toString(),
      phone: formData.phoneNumber,
      company: formData.companyName,
      message: formData.message || '',
      currency: formData.currency,
      amount: formData.amount,
    });
  }

  const currencyFieldState = watch('currency');

  useEffect(() => {
    calculateEstimatedReward(
      getValues('amount'),
      getValues('period'),
      currencyFieldState
    );
  }, [currencyFieldState]);

  return (
    <Modal open={open} onClose={handleClose} disableBackdropClick>
      <div className={classes.container}>
        <div className={classes.heading}>
          <Typography className={classes.title}>Request for staking</Typography>
          <IconButton className={classes.btnClose} onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={classes.currencyBox}>
            <Controller
              name="currency"
              control={control}
              render={({ field: { onChange, value } }) => (
                <div>
                  <Typography className={classes.currencyTitle} component="p">
                    Currency
                  </Typography>
                  <Select
                    onChange={(e) => {
                      const selectedAsset = availableAssets.find(
                        (a) => a.currency === e.target.value
                      )!;
                      onChange(selectedAsset.currency);
                    }}
                    value={value}
                    variant="outlined"
                    fullWidth
                    className={classes.currencySelect}
                    error={!!errors?.currency?.message}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left',
                      },
                      transformOrigin: {
                        vertical: 'top',
                        horizontal: 'left',
                      },
                      getContentAnchorEl: null,
                    }}
                  >
                    {availableAssets.map((asset) => (
                      <MenuItem
                        className={classes.menuItemContainer}
                        key={asset.currency}
                        value={asset.currency}
                      >
                        <div className={classes.menuItem}>
                          <CurrencyIcon title={asset.currency} />
                          <Typography component="p">
                            {asset.name}
                            <Typography component="span">
                              {asset.currency}
                            </Typography>
                          </Typography>
                        </div>
                      </MenuItem>
                    ))}
                  </Select>
                </div>
              )}
            />
          </div>
          <Controller
            name="amount"
            control={control}
            render={({ field: { onChange, value } }) => (
              <InputOutlined
                onChange={(e) => {
                  onChange(e);
                  calculateEstimatedReward(
                    getValues('amount'),
                    getValues('period'),
                    currencyFieldState
                  );
                }}
                value={value}
                label="Amount"
                type="number"
                helperText={errors?.amount?.message}
                variant="outlined"
                fullWidth
                className={classes.numberInput}
                placeholder="Enter your value"
                error={!!errors?.amount?.message}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {currencyFieldState}
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
          <Controller
            name="period"
            control={control}
            render={({ field: { onChange, value } }) => (
              <div>
                <InputOutlined
                  onChange={(e) => {
                    onChange(e);
                    calculateEstimatedReward(
                      getValues('amount'),
                      getValues('period'),
                      currencyFieldState
                    );
                  }}
                  value={value}
                  label="Period"
                  helperText={errors?.period?.message}
                  variant="outlined"
                  fullWidth
                  type="number"
                  placeholder="Enter period"
                  className={classes.numberInput}
                  error={!!errors?.period?.message}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">Days</InputAdornment>
                    ),
                  }}
                />
                <ButtonGroup
                  size="medium"
                  fullWidth
                  className={classes.btnsContainer}
                >
                  <Button
                    data-cy="staking_request_btn_30"
                    className={clsx(classes.btnPercentage, {
                      [classes.selected]: +value === 30,
                    })}
                    onClick={() => {
                      onChange(30);
                      calculateEstimatedReward(
                        getValues('amount'),
                        getValues('period'),
                        currencyFieldState
                      );
                    }}
                  >
                    30 Days
                  </Button>
                  <Button
                    data-cy="staking_request_btn_90"
                    className={clsx(classes.btnPercentage, {
                      [classes.selected]: +value === 90,
                    })}
                    onClick={() => {
                      onChange(90);
                      calculateEstimatedReward(
                        getValues('amount'),
                        getValues('period'),
                        currencyFieldState
                      );
                    }}
                  >
                    90 Days
                  </Button>
                  <Button
                    data-cy="staking_request_btn_365"
                    className={clsx(classes.btnPercentage, {
                      [classes.selected]: +value === 365,
                    })}
                    onClick={() => {
                      onChange(365);
                      calculateEstimatedReward(
                        getValues('amount'),
                        getValues('period'),
                        currencyFieldState
                      );
                    }}
                  >
                    365 Days
                  </Button>
                </ButtonGroup>
              </div>
            )}
          />
          <ul className={classes.list}>
            <li className={classes.listItem}>
              <Typography color="textSecondary">Approximate APY</Typography>
              <Typography>
                <b>
                  {
                    availableAssets.find(
                      (asset) => asset.currency === currencyFieldState
                    )?.stackingAPY
                  }
                  %
                </b>
              </Typography>
            </li>
            <li className={classes.listItem}>
              <Typography color="textSecondary">Estimated Reward</Typography>
              <Controller
                name="reward"
                control={control}
                render={({ field: { value } }) => (
                  <Typography>
                    <b>
                      {value} {currencyFieldState}
                    </b>
                  </Typography>
                )}
              />
            </li>
          </ul>
          <div className={classes.grid}>
            <Controller
              name="fullName"
              control={control}
              render={({ field: { onChange, value } }) => (
                <InputOutlined
                  onChange={onChange}
                  value={value}
                  label="Full name"
                  helperText={errors?.fullName?.message}
                  variant="outlined"
                  fullWidth
                  placeholder="Enter your name"
                  error={!!errors?.fullName?.message}
                />
              )}
            />
            <Controller
              name="companyName"
              control={control}
              render={({ field: { onChange, value } }) => (
                <InputOutlined
                  onChange={onChange}
                  value={value}
                  fullWidth
                  label="Company name"
                  helperText={errors?.companyName?.message}
                  variant="outlined"
                  placeholder="Enter name of your company"
                  error={!!errors?.companyName?.message}
                />
              )}
            />
          </div>
          <div className={classes.grid}>
            <Controller
              name="email"
              control={control}
              render={({ field: { onChange, value } }) => (
                <InputOutlined
                  onChange={onChange}
                  value={value}
                  fullWidth
                  label="Company email"
                  helperText={errors?.email?.message}
                  variant="outlined"
                  placeholder="Enter email of your company"
                  error={!!errors?.email?.message}
                />
              )}
            />
            <Controller
              name="phoneNumber"
              control={control}
              render={({ field: { onChange, value } }) => (
                <InputOutlined
                  onChange={onChange}
                  value={value}
                  fullWidth
                  label="Phone number"
                  helperText={errors?.phoneNumber?.message}
                  variant="outlined"
                  placeholder="Enter phone number"
                  error={!!errors?.phoneNumber?.message}
                />
              )}
            />
          </div>
          <Controller
            name="message"
            control={control}
            render={({ field: { onChange, value } }) => (
              <InputOutlined
                onChange={onChange}
                value={value}
                fullWidth
                multiline
                rows={3}
                className={classes.field}
                label="Your message"
                helperText={errors?.message?.message}
                variant="outlined"
                placeholder="Enter your message"
                error={!!errors?.message?.message}
              />
            )}
          />
          <div className={clsx(classes.grid, classes.btns)}>
            <Button
              disabled={submitting}
              className={classes.btnCancel}
              variant="outlined"
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button variant="outlined" type="submit" disabled={submitting}>
              Submit
            </Button>
          </div>
        </form>
      </div>
    </Modal>
  );
}

export default RequestStakingModal;
