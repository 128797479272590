import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

import EMPTY from '../../static/currency/empty.svg';
import ONEINCH from '../../static/currency/1inch.svg';
// ETHT

import AAVE from '../../static/currency/aave.svg';
import ADA from '../../static/currency/ada.svg';
import AION from '../../static/currency/aion.svg';
import AKRO from '../../static/currency/akro.svg';
import ALGO from '../../static/currency/algo.svg';
import ALPHA from '../../static/currency/alpha.svg';
import AMPL from '../../static/currency/ampl.svg';
import ANT from '../../static/currency/ant.svg';
import AOA from '../../static/currency/aoa.svg';
import APE from '../../static/currency/ape.svg';
import API3 from '../../static/currency/api3.svg';
import ARPA from '../../static/currency/arpa.svg';
import ATOM from '../../static/currency/atom.svg';
import AUDIO from '../../static/currency/audio.svg';
import AVAX from '../../static/currency/avax.svg';
import AWG from '../../static/currency/awg.svg';
import AWP from '../../static/currency/awp.svg';
import AWS from '../../static/currency/aws.svg';
import AWX from '../../static/currency/awx.svg';
import AXS from '../../static/currency/axs.svg';

import BAL from '../../static/currency/bal.svg';
import BAND from '../../static/currency/band.svg';
import BAT from '../../static/currency/bat.svg';
import BCH from '../../static/currency/bch.svg';
import BCHA from '../../static/currency/bcha.svg';
import BEL from '../../static/currency/bel.svg';
import BNB from '../../static/currency/bnb.svg';
import BNT from '../../static/currency/bnt.svg';
import BSV from '../../static/currency/bsv.svg';
import BTC from '../../static/currency/btc.svg';
import BTCT from '../../static/currency/btct.svg';
import BTTC from '../../static/currency/bttc.svg';
import BOBA from '../../static/currency/boba.svg';
import BTG from '../../static/currency/btg.svg';
import BTT from '../../static/currency/btt.svg';
import BUSD from '../../static/currency/busd.svg';

import CAKE from '../../static/currency/cake.svg';
import CHZ from '../../static/currency/chz.svg';
import COMP from '../../static/currency/comp.svg';
import COTI from '../../static/currency/coti.svg';
import CREAM from '../../static/currency/cream.svg';
import CRO from '../../static/currency/cro.svg';
import CRV from '../../static/currency/crv.svg';
import CVC from '../../static/currency/cvc.svg';

import DAI from '../../static/currency/dai.svg';
import DASH from '../../static/currency/dash.svg';
import DEA from '../../static/currency/dea.svg';
import DFN from '../../static/currency/dfn.svg';
import DGB from '../../static/currency/dgb.svg';
import DNT from '../../static/currency/dnt.svg';
import DOGE from '../../static/currency/doge.svg';
import DOT from '../../static/currency/dot.svg';
import DPI from '../../static/currency/dpi.svg';
import DUSK from '../../static/currency/dusk.svg';
import DYDX from '../../static/currency/dydx.svg';

import EDGT from '../../static/currency/edgt.svg';
import EGLD from '../../static/currency/egld.svg';
import ENJ from '../../static/currency/enj.svg';
import EOS from '../../static/currency/eos.svg';
import ETC from '../../static/currency/etc.svg';
import ETH from '../../static/currency/eth.svg';
import EUR from '../../static/currency/eur.svg';
import EURS from '../../static/currency/eurs.svg';
import EVER from '../../static/currency/ever.svg';

import FARM from '../../static/currency/farm.svg';
import FIL from '../../static/currency/fil.svg';
import FLR from '../../static/currency/flr.svg';
import FUN from '../../static/currency/fun.svg';
import FTM from '../../static/currency/ftm.svg';

import GAS from '../../static/currency/gas.svg';
import GBP from '../../static/currency/gbp.svg';
import GHST from '../../static/currency/ghst.svg';
import GLM from '../../static/currency/glm.svg';
import GNO from '../../static/currency/gno.svg';
import GRT from '../../static/currency/grt.svg';
import GUSD from '../../static/currency/gusd.svg';
import GZIL from '../../static/currency/gzil.svg';

import HAPI from '../../static/currency/hapi.svg';
import HEGIC from '../../static/currency/hegic.svg';
import HOT from '../../static/currency/hot.svg';
import HT from '../../static/currency/ht.svg';

import ICP from '../../static/currency/iota.svg';
import INJ from '../../static/currency/inj.svg';
import IOTA from '../../static/currency/iota.svg';
import IOTX from '../../static/currency/iotx.svg';

import JST from '../../static/currency/jst.svg';
import JASMY from '../../static/currency/jasmy.svg';

import KAVA from '../../static/currency/kava.svg';
import KNC from '../../static/currency/knc.svg';
import KSM from '../../static/currency/ksm.svg';
import KP3R from '../../static/currency/kp3r.svg';

import LAMB from '../../static/currency/lamb.svg';
import LEND from '../../static/currency/lend.svg';
import LEO from '../../static/currency/leo.svg';
import LINK from '../../static/currency/link.svg';
import LRC from '../../static/currency/lrc.svg';
import LTC from '../../static/currency/ltc.svg';
import LUA from '../../static/currency/lua.svg';
import LUNA from '../../static/currency/luna.svg';
import LUNR from '../../static/currency/lunr.svg';
import LUNC from '../../static/currency/lunc.svg';

import MANA from '../../static/currency/mana.svg';
import MATIC from '../../static/currency/matic.svg';
import MINA from '../../static/currency/mina.svg';
import MHC from '../../static/currency/mhc.svg';
import MKR from '../../static/currency/mkr.svg';
import MTA from '../../static/currency/mta.svg';
import MUSD from '../../static/currency/musd.svg';

import NEO from '../../static/currency/neo.svg';
import NEXO from '../../static/currency/nexo.svg';
import NFT from '../../static/currency/nft.svg';
import NMR from '../../static/currency/nmr.svg';
import NU from '../../static/currency/nu.svg';
import NXM from '../../static/currency/nxm.svg';

import OCEAN from '../../static/currency/ocean.svg';
import OMG from '../../static/currency/omg.svg';
import ONG from '../../static/currency/ong.svg';
import ONT from '../../static/currency/ont.svg';
import OXT from '../../static/currency/oxt.svg';

import PAX from '../../static/currency/pax.svg';
import PAXG from '../../static/currency/paxg.svg';
import PICKLE from '../../static/currency/pickle.svg';
import PLN from '../../static/currency/pln.svg';

import QASH from '../../static/currency/qash.svg';
import QTUM from '../../static/currency/qtum.svg';
import QNT from '../../static/currency/qnt.svg';
import QRDO from '../../static/currency/qrdo.svg';

import RARI from '../../static/currency/rari.svg';
import REN from '../../static/currency/ren.svg';
import RENBTC from '../../static/currency/renbtc.svg';
import REP from '../../static/currency/rep.svg';
import REPV2 from '../../static/currency/repv2.svg';
import ROOBEE from '../../static/currency/roobee.svg';
import RSR from '../../static/currency/rsr.svg';
import RUB from '../../static/currency/rub.svg';
import RVN from '../../static/currency/rvn.svg';

import SAND from '../../static/currency/sand.svg';
import SGB from '../../static/currency/sgb.svg';
import SHIB from '../../static/currency/shib.svg';
import SNX from '../../static/currency/snx.svg';
import SOL from '../../static/currency/sol.svg';
import SRM from '../../static/currency/srm.svg';
import STORJ from '../../static/currency/storj.svg';
import SUSHI from '../../static/currency/sushi.svg';
import SXP from '../../static/currency/sxp.svg';

import TAEL from '../../static/currency/tael.svg';
import TBTC from '../../static/currency/tbtc.svg';
import THETA from '../../static/currency/theta.svg';
import TON from '../../static/currency/ton_crystal.svg';
import TLM from '../../static/currency/tlm.svg';
import TRB from '../../static/currency/trb.svg';
import TRX from '../../static/currency/trx.svg';
import TUSD from '../../static/currency/tusd.svg';
import TWT from '../../static/currency/twt.svg';

import UMA from '../../static/currency/uma.svg';
import UNI from '../../static/currency/uni.svg';
import USD from '../../static/currency/usd.svg';
import USDC from '../../static/currency/usdc.svg';
import USDT from '../../static/currency/usdt.svg';
import UST from '../../static/currency/ust.svg';
import UTK from '../../static/currency/utk.svg';

import VALUE from '../../static/currency/value.svg';
import VET from '../../static/currency/vet.svg';

import WABI from '../../static/currency/wabi.svg';
import WAVES from '../../static/currency/waves.svg';
import WBTC from '../../static/currency/wbtc.svg';
import WIN from '../../static/currency/win.svg';
import WNXM from '../../static/currency/wnxm.svg';

import XDC from '../../static/currency/xdc.svg';
import XEC from '../../static/currency/xec.svg';
import XLM from '../../static/currency/xlm.svg';
import XRP from '../../static/currency/xrp.svg';
import XSGD from '../../static/currency/xsgd.svg';
import XTRD from '../../static/currency/xtrd.svg';
import XTZ from '../../static/currency/xtz.svg';
import XVS from '../../static/currency/xvs.svg';

import YFI from '../../static/currency/yfi.svg';
import YFII from '../../static/currency/yfii.svg';
import YFV from '../../static/currency/yfv.svg';

import ZAP from '../../static/currency/zap.svg';
import ZEC from '../../static/currency/zec.svg';
import ZIL from '../../static/currency/zil.svg';
import ZRX from '../../static/currency/zrx.svg';
import ZWAP from '../../static/currency/zwap.svg';

const icons: { [key: string]: any } = {
  '1INCH': ONEINCH,
  AAVE,
  ADA,
  AION,
  AKRO,
  ALGO,
  ALPHA,
  AMPL,
  ANT,
  AOA,
  APE,
  API3,
  ARPA,
  ATOM,
  AUDIO,
  AVAX,
  AWG,
  AWP,
  AWS,
  AWX,
  AXS,
  BAL,
  BAND,
  BAT,
  BCH,
  BCHA,
  BEL,
  BNB,
  BNT,
  BSV,
  BTC,
  BTCT,
  BTTC,
  BOBA,
  BTG,
  BTT,
  BUSD,
  CAKE,
  CHZ,
  COMP,
  COTI,
  CREAM,
  CRO,
  CRV,
  CVC,
  DAI,
  DASH,
  DEA,
  DFN,
  DGB,
  DNT,
  DOGE,
  DOT,
  DPI,
  DUSK,
  DYDX,
  EDGT,
  EGLD,
  ENJ,
  EOS,
  ETC,
  ETH,
  EUR,
  EURS,
  EVER,
  FARM,
  FIL,
  FLR,
  FUN,
  FTM,
  GAS,
  GBP,
  GHST,
  GLM,
  GNO,
  GRT,
  GUSD,
  GZIL,
  HAPI,
  HEGIC,
  HOT,
  HT,
  ICP,
  INJ,
  IOTA,
  IOTX,
  JST,
  JASMY,
  KAVA,
  KNC,
  KSM,
  KP3R,
  LAMB,
  LEND,
  LEO,
  LINK,
  LRC,
  LTC,
  LUA,
  LUNA,
  LUNC,
  LUNR,
  MANA,
  MATIC,
  MHC,
  MKR,
  MTA,
  MUSD,
  MINA,
  NEO,
  NFT,
  NEXO,
  NMR,
  NU,
  NXM,
  OCEAN,
  OMG,
  ONG,
  ONT,
  OXT,
  PAX,
  PAXG,
  PICKLE,
  PLN,
  QASH,
  QTUM,
  QNT,
  QRDO,
  RARI,
  REN,
  RENBTC,
  REP,
  REPV2,
  ROOBEE,
  RSR,
  RUB,
  RVN,
  SAND,
  SGB,
  SHIB,
  SNX,
  SOL,
  SRM,
  STORJ,
  SUSHI,
  SXP,
  TAEL,
  TBTC,
  THETA,
  TON,
  TRB,
  TRX,
  TLM,
  TUSD,
  TWT,
  UMA,
  UNI,
  USD,
  USDC,
  USDT,
  UST,
  UTK,
  VALUE,
  VET,
  WABI,
  WAVES,
  WBTC,
  WIN,
  WNXM,
  XDC,
  XEC,
  XLM,
  XRP,
  XSGD,
  XTRD,
  XTZ,
  XVS,
  YFI,
  YFII,
  YFV,
  ZAP,
  ZEC,
  ZIL,
  ZRX,
  ZWAP,
};

const useStyles = makeStyles(() => ({
  img: {
    width: 24,
    height: 24,
    borderRadius: 8,
    backgroundColor: (props: any) =>
      props.isTransparent ? 'transparent' : '#ffffff',
  },
}));

interface IProps {
  title: string;
  className?: string;
}

const ICONS_NO_BACKGROUND = [DYDX];

export default function CurrencyIcon({ title, className }: IProps) {
  const classes = useStyles({
    isTransparent: ICONS_NO_BACKGROUND.includes(icons[title]),
  });
  return (
    <img
      className={clsx(classes.img, className)}
      src={icons[title] || EMPTY}
      alt={title}
    />
  );
}
