const MuiTypography = {
  root: {
    color: '#ffffff',
  },
  colorTextSecondary: {
    color: 'rgba(255, 255, 255, 0.6)',
  },
  body1: {
    fontSize: 14,
    lineHeight: '20px',
  },
};

export default MuiTypography;
