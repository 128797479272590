import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import { Field } from 'react-final-form';
import SelectComp, { components } from 'react-select';

import { useInputStyles } from './Input';

const DropdownIndicator = (
  props: any
) => {
  return (
    <components.DropdownIndicator {...props}>
      <svg
        width="10"
        height="6"
        viewBox="0 0 10 6"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ transform: props.selectProps.menuIsOpen ? 'rotate(180deg)' : 'none' }}
      >
        <path d="M4.8488 5.33333L9.51546 0H0.182129L4.8488 5.33333Z" fill={props.selectProps.menuIsOpen ? '#1BB6C1' : 'black'} />
      </svg>
    </components.DropdownIndicator>
  );
};

function SelectField({ input, label, placeholder, textarea, required, meta, options, ...rest }: any) {
  const classes = useInputStyles();
  const theme: any = useTheme();

  return (
    <div className={classes.root}>
      <label className={classes.label}>{label}{required && '*'}</label>
      <SelectComp
        {...input}
        placeholder={placeholder}
        options={options}
        {...rest}
        components={{ DropdownIndicator }}
        isMulti={false}
        isSearchable={false}
        styles={{
          control: () => ({
            display: 'flex',
            border: '1px solid #6C6C6C',
            height: 48,
            fontSize: 14,
            lineHeight: '20px',
            width: '100%',
            borderRadius: 0,
            padding: '14px 8px',
            '&:active, &:focus, &:hover': {
              border: '1px solid #2F2F2F',
              outline: 'none !important'
            },
            '&.error': {
              borderColor: 'red'
            }
          }),
          indicatorSeparator: () => ({ display: 'none' }),
          menu: (styles: any) => ({
            ...styles,
            borderRadius: 0,
            border: '1px solid #2F2F2F',
            marginTop: -1,
            boxShadow: 'none',
            '& > div': {
              paddingTop: 0,
              paddingBottom: 0,
            }
          }),
          option: () => ({
            boxShadow: 'none',
            height: 48,
            fontSize: 14,
            lineHeight: '20px',
            padding: '14px 24px',
            cursor: 'pointer',
            color: '#000',
            '&:last-child': {
              borderBottom: 'none'
            },
            '&:hover': {
              background: `${theme.palette.link.light} !important`,
              color: `${theme.palette.link.main} !important`,
            },
            '&:active': {
              color: `${theme.palette.link.main} !important`,
            }
          }),
        }}
      />
    </div>
  )
}

interface IOption {
  value: string;
  label: string;
}

interface IProps {
  name: string;
  label: string;
  placeholder?: string;
  required?: boolean;
  validate?: any;
  options: IOption[];
}

export default function Select({ name, ...rest }: IProps) {
  return (
    <Field
      name={name}
      component={SelectField}
      {...rest}
    />
  )
}
