import React, { useState, useEffect, useRef, ReactChild } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { useMediaQuery } from 'react-responsive';
import { MAX_TABLET_BREAKPOINT } from '../../consts';

export const useStyles = makeStyles((theme: any) => ({
  wrapper: {
    cursor: 'pointer',
    userSelect: 'none',
    position: 'relative',
    '& .selected-title': {
      color: '#2BCBD7',
    },
    '& .chevron-icon': {
      transform: 'rotate(0deg)',
      transition: 'all 300ms ease 0s',
    },
  },
  active: {
    '&:before': {
      position: 'absolute',
      content: '""',
      height: '60px',
      width: '100%',
      left: 0,
      bottom: -60,
      cursor: 'auto',
      [theme.breakpoints.sm]: {
        content: 'none',
      },
    },
  },
  hidden: {
    display: 'none!important',
  },
  icon: {
    marginRight: 8,
    [theme.breakpoints.sm]: {
      display: 'none',
    },
  },
  selected: {
    '& .chevron-icon': {
      transform: 'rotate(180deg)',
      transition: 'all 300ms ease 0s',
      '& path': {
        fill: '#2BCBD7',
      },
    },
    '& .transfer-icon path': {
      fill: '#2BCBD7',
    },
    [theme.breakpoints.sm]: {
      backgroundColor: '#161616',
    },
  },
  container: {
    paddingTop: 2.5,
    height: 19,
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    '&:after': {
      content: '" "',
      position: 'absolute',
      left: -14,
      background: 'rgba(255, 255, 255, 0.1)',
      width: '100%',
      height: '100%',
      padding: '9px 14px',
      [theme.breakpoints.sm]: {
        content: 'none',
      },
    },
    [theme.breakpoints.sm]: {
      height: 'auto',
      width: '100%',
      justifyContent: 'space-between',
      padding: '16px 42px',
      borderTop: '1px solid #2f2f2f',
    },
    [theme.breakpoints.xs]: {
      padding: '16px 22px',
      paddingRight: 26,
    },
  },
  title: {
    marginRight: 6,
    fontSize: 14,
    lineHeight: '20px',
    color: '#ffffff',
    [theme.breakpoints.sm]: {
      marginRight: 0,
      fontSize: 16,
      lineHeight: '24px',
    },
  },
  items: {
    padding: 32,
    position: 'absolute',
    top: 52,
    display: 'flex',
    background: '#000000',
    border: '1px solid #2F2F2F',
    cursor: 'initial',
    [theme.breakpoints.sm]: {
      border: 0,
      borderTop: '2px solid #2F2F2F',
      padding: '16px 48px',
      flexDirection: 'column',
      position: 'initial',
      background: '#161616',
    },
    '@media screen and (max-width: 480px)': {
      padding: '16px 24px',
    },
  },
  loadingText: {
    fontSize: 14,
    color: '#999',
  },
}));

interface IProps {
  title: string;
  children: ReactChild | ReactChild[];
  className?: string;
  classNameMenu?: string;
  loading?: boolean;
}

function HeaderDropdown({
  title,
  children,
  className,
  classNameMenu,
  loading,
}: IProps) {
  const classes = useStyles();
  const [active, setActive] = useState(false);
  const elRef = useRef<HTMLLIElement>(null);
  const isTabletOrMobile = useMediaQuery({
    query: `(max-width: ${MAX_TABLET_BREAKPOINT})`,
  });

  useEffect(() => {
    function handleClickOutside(e: any) {
      if (elRef.current && !elRef.current.contains(e.target)) {
        setActive(false);
      }
    }
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [elRef]);

  return (
    <li
      ref={elRef}
      className={clsx(classes.wrapper, className, { [classes.active]: active })}
      onMouseLeave={() => !isTabletOrMobile && setActive(false)}
      onMouseEnter={() => !isTabletOrMobile && setActive(true)}
    >
      <div
        className={clsx(classes.container, { [classes.selected]: active })}
        onClick={() => setActive((v) => !v)}
      >
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={clsx(classes.icon, 'transfer-icon')}
        >
          <path
            d="M10.6654 8.00008L13.332 5.33341L10.6654 2.66675V4.66608H1.33203V5.99941H10.6654V8.00008ZM5.33203 8.00008L2.66536 10.6667L5.33203 13.3334V11.3334H14.6654V10.0001H5.33203V8.00008Z"
            fill="white"
          />
        </svg>
        <span className={clsx(classes.title, { 'selected-title': active })}>
          {title}
        </span>
        <svg
          width="24"
          height="25"
          className="chevron-icon"
          viewBox="0 0 24 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M16.293 9.79297L12 14.086L7.70697 9.79297L6.29297 11.207L12 16.914L17.707 11.207L16.293 9.79297Z"
            fill="white"
          />
        </svg>
      </div>
      <ul
        className={clsx(classes.items, classNameMenu, 'menu', {
          [classes.hidden]: !active,
        })}
      >
        {loading || !children.toString().length ? (
          <small className={classes.loadingText}>Loading...</small>
        ) : (
          children
        )}
      </ul>
    </li>
  );
}

export default HeaderDropdown;
