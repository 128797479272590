import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import useDimension from '../../hooks/useDimension';

interface IProps {
  children: JSX.Element | JSX.Element[];
  className?: string;
  maxWidth?: string;
  background?: string;
}

interface IStyleProps {
  maxWidth?: string;
  background?: string;
  isTabletOrMobile: boolean;
  isMobile: boolean;
}

const useStyles = makeStyles((theme: any) => ({
  container: {
    background: ({ background }: IStyleProps) =>
      background ? background : 'inherit',
  },
  content: {
    width: ({ maxWidth, isTabletOrMobile }: IStyleProps) =>
      maxWidth && !isTabletOrMobile ? maxWidth : 'auto',
    margin: '0 auto',
  },
}));

function ContentBox({ children, className, maxWidth, background }: IProps) {
    const { isTabletOrMobile, isMobile } = useDimension();
    const classes = useStyles({ maxWidth, background, isTabletOrMobile, isMobile });

  return (
    <div className={clsx(classes.container, className)}>
      <div className={clsx(classes.content, 'content_box-content')}>{children}</div>
    </div>
  );
}

export default ContentBox;
