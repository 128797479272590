import React from 'react';
import { TextField, TextFieldProps, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme: any) => ({
  label: {
    marginBottom: theme.spacing(1),
    fontSize: 12,
    lineHeight: '18px',
    color: '#ffffff',
  },
}));

function InputOutlined(props: TextFieldProps) {
  const classes = useStyles();

  return (
    <div>
      <Typography className={classes.label}>{props.label}</Typography>
      <TextField {...props} variant="outlined" label={undefined} />
    </div>
  );
}

export default InputOutlined;
