import axios from 'axios';
import { useState } from 'react';

const apiUrl = process.env.REACT_APP_API_URL || '/api';

const Api = axios.create({
  baseURL: apiUrl,
});

export default Api;

export function useFetchApi(
  remote: any,
  initialData: any,
  fetchOnLoad = false
) {
  const [state, setState] = useState({ ...initialData, error: null });
  const [loading, setLoading] = useState(fetchOnLoad);

  const load = async (...args: any) => {
    setLoading(true);
    let res: any = {};
    try {
      res = await remote(...args);
    } catch (e) {
      // @ts-ignore
      setState({ ...state, error: e.toString() });
    }

    if (res.data) {
      if (res.data.error) {
        setState({ ...state, error: res.data.error });
      } else {
        setState({
          ...state,
          ...res.data,
          error: null,
        });
      }
    }

    setLoading(false);
  };

  return [state, loading, load];
}

export function useUpdateApi(remote: any, callback: any) {
  const [updating, setUpdating] = useState(false);

  const req = async (...args: any) => {
    setUpdating(true);

    try {
      const res = await remote(...args);
      if (res.data.error) {
        console.error(res.data.error);
      }
      callback(res.data);
    } catch (e) {
      // @ts-ignore
      console.error('REQUEST ERROR: ' + e.toString());
      callback(e);
    }

    setUpdating(false);
  };

  return [req, updating];
}

export const getUserProfileIsoCountry = async () => {
  try {
    const reqUrl = process.env.REACT_APP_CONTACT_CENTER_URL
    const resp = await fetch(`${reqUrl}/api/profile`, { credentials: 'include' })
    const result = await resp.json()

    return result.iso_31661
  } catch (error) {
    // eslint-disable-next-line no-console
    console.warn('getUserProfileIsoCountry error: ', error)
    return undefined
  }
}
