import React, { useRef, useState } from 'react';
import { Grow, Slide } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ContentBox from '../../components/ContentBox';
import Section from '../../components/Section';
import useDimension from '../../hooks/useDimension';
import useScrollListener from '../../hooks/useScrollListener';

import ShieldIcon from '../../static/images/shield_icon.svg';
import CertificateIcon from '../../static/images/certificate_icon.svg';
import SitemapIcon from '../../static/images/sitemap_icon.svg';
import TeamworkIcon from '../../static/images/teamwork_icon.svg';
import CexImage from '../../static/images/cex_dark_bg.png';
import CubesImage from '../../static/images/cubes_bg.png';
import MapImage from '../../static/images/bg_map.png';

const useStyles = makeStyles((theme: any) => ({
  container: {
    overflowX: 'hidden',
  },
  title: {
    marginTop: theme.spacing(11),
    marginBottom: theme.spacing(10),
    textAlign: 'center',
    fontSize: 72,
    lineHeight: '84px',
    fontWeight: 400,
    textTransform: 'uppercase',
    [theme.breakpoints.sm]: {
      marginTop: theme.spacing(5),
      marginBottom: theme.spacing(7),
      fontSize: 64,
      lineHeight: '72px',
    },
    [theme.breakpoints.xs]: {
      fontSize: 40,
      lineHeight: '48px',
    },
  },
  approachSection: {
    marginBottom: theme.spacing(10),
    '& .content_box-content': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    [theme.breakpoints.sm]: {
      '& .content_box-content': {
        display: 'flex',
        flexDirection: 'column',
      },
    },
    [theme.breakpoints.xs]: {
      '& .content_box-content': {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
      },
    },
  },
  approach: {
    marginRight: 130,
    '& h6': {
      marginBottom: theme.spacing(1),
      fontSize: 48,
      fontWeight: 400,
      lineHeight: '64px',
      textTransform: 'uppercase',
      [theme.breakpoints.sm]: {
        fontSize: 36,
        lineHeight: '44px',
        textAlign: 'center',
      },
      [theme.breakpoints.xs]: {
        marginBottom: theme.spacing(2),
        fontSize: 28,
        lineHeight: '36px',
      },
    },
    '& p': {
      maxWidth: 444,
      fontSize: 18,
      lineHeight: '26px',
      color: '#999999',
      [theme.breakpoints.sm]: {
        textAlign: 'center',
      },
      [theme.breakpoints.xs]: {
        marginBottom: theme.spacing(2),
      },
    },
    [theme.breakpoints.sm]: {
      marginRight: 0,
      marginBottom: theme.spacing(5),
    },
  },
  list: {
    display: 'grid',
    gap: '27px 48px',
    gridTemplateColumns: '1fr 1fr',
    gridTemplateRows: '1fr 1fr',
    gridTemplateAreas: `". ."
                        ". ."`,
    '& li': {
      maxWidth: 310,
      '& p': {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(1.5),
        fontSize: 20,
        fontWeight: 600,
        lineHeight: '28px',
      },
      '& span': {
        fontSize: 18,
        lineHeight: '26px',
        color: '#999999',
      },
    },
    [theme.breakpoints.sm]: {
      '& li': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        '& span': {
          textAlign: 'center',
        },
      },
    },
    [theme.breakpoints.xs]: {
      gridTemplateColumns: '1fr',
      gridTemplateRows: '1fr 1fr 1fr 1fr',
      gap: '40px',
      gridTemplateAreas: `"."
      "."
      "."
      "."`,
      '& li': {
        maxWidth: 327,
      },
    },
  },
  cexSection: {
    paddingBottom: theme.spacing(13),
    backgroundColor: '#161616',
    [theme.breakpoints.xs]: {
      paddingBottom: 0,
    },
  },
  cexGroupSection: {
    paddingBottom: theme.spacing(13),
    backgroundColor: '#ffffff',
    '& .section-title': {
      color: '#000000',
    },
    [theme.breakpoints.xs]: {
      paddingBottom: 0,
    },
  },
  mapSection: {
    paddingTop: theme.spacing(13),
    paddingBottom: theme.spacing(8),
    [theme.breakpoints.sm]: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5),
    },
    [theme.breakpoints.xs]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  mapBlock: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& h6': {
      marginBottom: theme.spacing(3),
      maxWidth: 825,
      textAlign: 'center',
      fontSize: 44,
      lineHeight: '56px',
      fontWeight: 400,
      letterSpacing: '-0.02em',
      textTransform: 'uppercase',
    },
    '& p': {
      alignSelf: 'flex-start',
      marginBottom: theme.spacing(1),
      fontSize: 24,
      fontWeight: 700,
      lineHeight: '32px',
      textTransform: 'uppercase',
      color: '#00CBCB',
    },
    '& ul': {
      display: 'grid',
      gap: '16px 133px',
      gridTemplateColumns: '1fr 1fr',
      gridTemplateRows: '1fr 1fr',
      gridTemplateAreas: `". ."
                            "."`,
    },
    '& li': {
      fontSize: 18,
      lineHeight: '26px',
      color: '#999999',
      '& strong': {
        color: '#ffffff',
      },
    },
    '& a': {
      color: '#999999',
      textDecoration: 'none',
      fontSize: 18,
      lineHeight: '20px',
    },
    [theme.breakpoints.sm]: {
      '& h6': {
        marginBottom: theme.spacing(1),
        maxWidth: 570,
        fontSize: 34,
        lineHeight: '46px',
      },
      '& ul': {
        display: 'flex',
        flexDirection: 'column',
        gap: '16px',
      },
      '& #regulator_usa': {
        order: 1,
      },
    },
    [theme.breakpoints.xs]: {
      '& h6': {
        marginBottom: theme.spacing(5),
        fontSize: 28,
        lineHeight: '36px',
      },
      '& p': {
        marginBottom: theme.spacing(3),
      },
      '& ul': {
        gridTemplateColumns: '1fr',
        gridTemplateRows: '1fr 1fr',
        gridTemplateAreas: `"."
                            "."`,
      },
    },
  },
  mapImage: {
    width: '100%',
    [theme.breakpoints.sm]: {
      marginTop: theme.spacing(4),
    },
    [theme.breakpoints.xs]: {
      width: '260%',
      marginLeft: 240,
      marginTop: theme.spacing(8),
    },
  },
  link: {
    color: '#999999',
    textDecoration: 'none',
  },
}));

function AboutPage() {
  const classes = useStyles();
  const { isLargeScreen } = useDimension();
  const [animate, setAnimate] = useState(false);
  const mapRef = useRef<HTMLDivElement>(null);

  useScrollListener(mapRef, () => setAnimate(true));

  return (
    <main className={classes.container}>
      <Grow in timeout={500}>
        <h1 className={classes.title}>About us</h1>
      </Grow>
      <Grow in timeout={1000}>
        <div>
          <ContentBox
            maxWidth={isLargeScreen ? '1920px' : '1204px'}
            className={classes.approachSection}
          >
            <div className={classes.approach}>
              <h6>Our approach</h6>
              <p>
                CEX.IO Prime is a platform designed specifically for
                institutional and corporate clients. We strive to deliver the
                best customer service along with the industry leading expertise.
              </p>
            </div>
            <ul className={classes.list}>
              <li>
                <img src={ShieldIcon} alt="shield_icon" />
                <p>Trusted</p>
                <span>
                  CEX.IO is a regulated and multi-functional cryptocurrency
                  ecosystem originally established in London in 2013 and now
                  serves over 4.5 million customers worldwide.
                </span>
              </li>
              <li>
                <img src={SitemapIcon} alt="sitemap_icon" />
                <p>Efficient</p>
                <span>
                  CEX.IO has a rich, global network of banking and financial
                  partners, and we’re proud of our innovative technology.
                </span>
              </li>
              <li>
                <img src={TeamworkIcon} alt="teamwork_icon" />
                <p>Customer centric</p>
                <span>
                  All CEX.IO Prime clients are assigned an experienced account
                  manager to build a strong and mutually beneficial
                  relationship.
                </span>
              </li>
              <li>
                <img src={CertificateIcon} alt="certificate_icon" />
                <p>Regulated</p>
                <span>
                  CEX.IO Prime is a globally licensed and regulated
                  institutional ecosystem. CEX.IO Corp is also registered with
                  FinCEN. For more information, please visit our{' '}
                  <a
                    href={process.env.REACT_APP_CEX_LEGAL_URL}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={classes.link}
                  >
                    Licenses and Registrations.
                  </a>
                </span>
              </li>
            </ul>
          </ContentBox>
        </div>
      </Grow>
      <ContentBox
        maxWidth={isLargeScreen ? '1920px' : '1204px'}
        className={classes.cexSection}
      >
        <Section
          image={CexImage}
          title="Who is CEX.IO Prime for?"
          text="CEX.IO Prime is an ecosystem of premium-grade services built specifically for  institutional and corporate clients. 
CEX.IO Prime is tailored to satisfy all the needs of enterprise clients within one digital asset platform. CEX.IO Prime delivers this via advanced technology solutions and state-of-the-art architecture."
        />
      </ContentBox>
      <ContentBox
        maxWidth={isLargeScreen ? '1920px' : '1204px'}
        className={classes.cexGroupSection}
      >
        <Section
          image={CubesImage}
          imageSide="right"
          title="CEX.IO Group"
          text="СEX.IO Group is one of the world’s leading cryptocurrency businesses. CEX.IO Group has a collection of service offerings ranging from a top-10 cryptocurrency exchange, crypto-backed loans and staking, as well as CEX.IO Prime - an institutional ecosystem."
        />
      </ContentBox>
      <Slide in={animate} direction="up" timeout={1000}>
        <div>
          <ContentBox
            maxWidth={isLargeScreen ? '1920px' : '1204px'}
            className={classes.mapSection}
          >
            <div className={classes.mapBlock} ref={mapRef}>
              <h6>CEX.IO Group global outreach and regulatory positions</h6>
              <p>Approved</p>
              <ul>
                <li>
                  <strong>Lithuania</strong> —{' '}<a
                  href={process.env.REACT_APP_CEX_URL}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {' '}
                  CEX.IO{' '}
                </a> EU VASP, UAB is registered
                  as the Virtual Asset Service Provider (VASP) and depository virtual
                  currency wallets operator in the State Enterprise Centre of Registers
                  of the Republic of Lithuania lists.
                </li>
                <li id="regulator_usa">
                  <strong>USA</strong> — Registered as Money Services Businesses
                  (MSB) with the Financial Crimes Enforcement Network (FinCEN),
                  a bureau of the US Department of the Treasury.
                </li>
                {/* <li>
                  <strong>UK</strong> — Cryptoasset business registration with
                  the Financial Conduct Authority of United Kingdom (FCA).
                </li> */}
              </ul>
              <img className={classes.mapImage} src={MapImage} alt="map_bg" />
            </div>
          </ContentBox>
        </div>
      </Slide>
    </main>
  );
}

export default AboutPage;
