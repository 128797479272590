import { makeStyles } from '@material-ui/core/styles';
import CexIcon from '../components/icons/logo.svg';

const useGlobalStyles = makeStyles((theme: any) => ({
  rootNode: {
    minHeight: '100vh',
    color: theme.palette.text.white,
    fontFamily: 'Helvetica, sans-serif',
    fontWeight: 400,
    '&.menuOpen': {
      overflow: 'hidden'
    },
  },
  container: {
    backgroundColor: theme.palette.background.primary,
    // paddingLeft: 104,
    // paddingRight: 104,
    [theme.breakpoints.sm]: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
    [theme.breakpoints.xs]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    }
  },
  logo: {
    height: 40,
    width: 260,
    background: `url('${CexIcon}') no-repeat left center / cover`,
    color: theme.palette.text.white,
    display: 'block',
    [theme.breakpoints.sm]: {
      width: 156,
      height: 24
    }
  },
  desktopView: {
    [theme.breakpoints.sm]: {
      display: 'none'
    }
  },
  mobileHide: {
    [theme.breakpoints.xs]: {
      display: 'none !important'
    }
  },
  mobileView: {
    display: 'none',
    [theme.breakpoints.sm]: {
      display: 'block'
    }
  },
  xsView: {
    display: 'none',
    [theme.breakpoints.xs]: {
      display: 'block'
    }
  },
  pageTitle: {
    fontSize: 72,
    lineHeight: '84px',
    textAlign: 'center',
    textTransform: 'uppercase',
    // color: theme.palette.text.white,
    fontWeight: 400,
    [theme.breakpoints.sm]: {
      fontSize: 64,
      lineHeight: '72px',
    },
    [theme.breakpoints.xs]: {
      fontSize: 40,
      lineHeight: '48px',
    },
    '&.prime': {
      [theme.breakpoints.xs]: {
        fontSize: 48,
        lineHeight: '56px'
      }
    }
  },
  pageDescription: {
    fontSize: 18,
    lineHeight: '26px',
    textAlign: 'center',
    maxWidth: 712,
    margin: '8px auto 44px',
    color: theme.palette.text.desk
  },
  pageAction: {
    height: 'auto',
    padding: '16px 45px',
    fontSize: 18,
    lineHeight: '26px',
    fontWeight: 600,
    [theme.breakpoints.xs]: {
      paddingLeft: '67px !important',
      paddingRight: '67px !important'
    }
  },
  sectionTitle: {
    fontSize: 48,
    lineHeight: '56px',
    textAlign: 'center',
    textTransform: 'uppercase',
    '&.prime': {
      textTransform: 'none',
      fontWeight: 700,
    },
    '&.dark': {
      color: theme.palette.text.dark
    },
    '&.no-upper': {
      textTransform: 'none'
    },
    [theme.breakpoints.sm]: {
      fontSize: 36,
      lineHeight: '44px',
    },
    [theme.breakpoints.xs]: {
      fontSize: 32
    }
  },
  sectionSubtitle: {
    fontSize: 18,
    lineHeight: '26px',
    textAlign: 'center',
    margin: '16px auto 50px',
    maxWidth: 1030
  },
}));

export default useGlobalStyles;
