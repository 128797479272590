import palette from '../palette';

const MuiSelectOverrides = {
  outlined: {
    padding: '13px 16px',
    paddingRight: '0!important',
  },
  icon: {
    color: '#ffffff',
  },
  selectMenu: {
    backgroundColor: palette.background.light,
  },
};

export default MuiSelectOverrides;
