import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: any) => ({
  root: {
    paddingTop: 20,
    paddingBottom: 20,
    verticalAlign: 'middle',
    fontWeight: 700,
    borderBottom: ({ border }: any) => border ? '1px solid #2F2F2F' : 'none',
    fontSize: ({ head }: any) => head ? 12 : 18,
    lineHeight: ({ head }: any) => head ? '18px' : '26px',
    color: ({ head }: any) => head ? '#6c6c6c' : 'inherit',
    textTransform: ({ head }: any) => head ? 'uppercase' : 'inherit',
    textAlign: ({ align }: any) => align,
    [theme.breakpoints.xs]: {
      paddingTop: 12,
      paddingBottom: 12,
      // fontSize: ({ head }: any) => head ? '10px !important' : '12px !important',
      // lineHeight: '18px !important'
    }
  }
}));

interface IProps {
  children?: any | any[];
  border?: boolean;
  head?: boolean;
  align?: 'left' | 'right' | 'center';
  className?: any;
  colSpan?: number;
}

export default function TableCell({ children, border, head, align = 'left', className, ...rest }: IProps) {
  const classes = useStyles({ border, head, align });

  return (
    <td className={clsx(classes.root, className)} {...rest}>{children || ''}</td>
  );
}
