import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '../../components/Button';
import { AppRoutes } from '../../Routes';

const useStyles = makeStyles((theme: any) => ({
  container: {
    height: 'calc(100vh - 90px)',
    overflowY: 'hidden',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.xs]: {
      height: 'calc(100vh - 120px)',
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  numbers: {
    marginBottom: theme.spacing(2),
    fontSize: 280,
    lineHeight: '280px',
    fontWeight: 400,
    color: '#2F2F2F',
    [theme.breakpoints.xs]: {
      fontSize: 150,
      lineHeight: '172px',
    },
  },
  title: {
    marginBottom: theme.spacing(1),
    fontSize: 36,
    lineHeight: '44px',
    fontWeight: 400,
    textTransform: 'uppercase',
    [theme.breakpoints.xs]: {
      fontSize: 28,
      lineHeight: '44px',
    },
  },
  subTitle: {
    marginBottom: theme.spacing(6),
    maxWidth: 439,
    fontSize: 18,
    lineHeight: '26px',
    fontWeight: 400,
    color: theme.palette.text.desk,
    textAlign: 'center',
    [theme.breakpoints.xs]: {
      marginBottom: theme.spacing(2),
      maxWidth: 320,
    },
  },
}));

function NotFound() {
  const classes = useStyles();

  return (
    <main className={classes.container}>
      <section className={classes.content}>
        <h1 className={classes.numbers}>404</h1>
        <h2 className={classes.title}>Page not found</h2>
        <p className={classes.subTitle}>
          Sorry, but the page you are looking for does not exist or is
          temporarily unavailable.
        </p>
        <Button
          width={267}
          variant="colored"
          component="a"
          href={AppRoutes.root}
        >
          Go to CEX.IO Prime
        </Button>
      </section>
    </main>
  );
}

export default NotFound;
