import React, { useRef, useState, RefObject } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import VerticalSection from './VerticalSection';
import HorizontalSection from './HorizontalSection';
import useDimension from '../../hooks/useDimension';
import useScrollListener from '../../hooks/useScrollListener';

interface IProps {
  title: string;
  text: string;
  runAnimationImmediately?: boolean;
  image?: string;
  icon?: JSX.Element;
  imageNode?: JSX.Element;
  children?: JSX.Element;
  className?: string;
  negativePadding?: number;
  mainAxis?: 'row' | 'column';
  imageSide?: 'left' | 'right';
  background?: 'grey' | 'white' | 'default';
}

export interface IChildProps extends IProps {
  isTabletOrMobile: boolean;
  isMobile: boolean;
  rootRef: RefObject<HTMLDivElement>;
  animate: boolean;
}

interface IStyleProps {
  isTabletOrMobile: boolean;
  isMobile: boolean;
  background?: 'grey' | 'white' | 'default';
  mainAxis?: 'row' | 'column';
  imageSide?: 'left' | 'right';
}

export const useStyles = makeStyles((theme: any) => ({
  root: {
    marginTop: 144,
    display: 'inline-flex',
    flexDirection: ({ mainAxis, isTabletOrMobile }: IStyleProps) =>
      mainAxis === 'row' && !isTabletOrMobile ? 'row' : 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: ({ background }: IStyleProps) =>
      background === 'white'
        ? '#ffffff'
        : background === 'grey'
        ? '#161616'
        : 'inherit',
    [theme.breakpoints.sm]: {
      marginTop: 0,
      padding: theme.spacing(6),
      flexDirection: 'column',
    },
    [theme.breakpoints.xs]: {
      padding: '48px 16px',
    },
  },
  image: {
    width: '100%',
    marginRight: ({ imageSide, mainAxis, isTabletOrMobile }) =>
      imageSide === 'left' &&
      mainAxis === 'row' &&
      !isTabletOrMobile &&
      theme.spacing(8),
    marginLeft: ({ imageSide, mainAxis, isTabletOrMobile }) =>
      imageSide === 'right' &&
      mainAxis === 'row' &&
      !isTabletOrMobile &&
      theme.spacing(8),
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: ({ mainAxis, isTabletOrMobile }: IStyleProps) =>
      mainAxis === 'column' || isTabletOrMobile ? 'center' : 'flex-start',
    marginBottom: ({ isTabletOrMobile, mainAxis }) =>
      (isTabletOrMobile || mainAxis === 'column') && theme.spacing(8),
    [theme.breakpoints.sm]: {
      marginBottom: ({ isTabletOrMobile, mainAxis }) =>
        (isTabletOrMobile || mainAxis === 'column') && theme.spacing(5),
    },
  },
  icon: {
    marginBottom: theme.spacing(4),
  },
  title: {
    marginBottom: theme.spacing(3),
    fontSize: 44,
    lineHeight: '56px',
    fontWeight: 400,
    letterSpacing: '-0.02em',
    color: ({ background }: IStyleProps) =>
      background !== 'white' ? '#ffffff' : '#000000',
    textAlign: ({ mainAxis, isMobile }: IStyleProps) =>
      mainAxis === 'column' || isMobile ? 'center' : 'inherit',
    [theme.breakpoints.xs]: {
      fontSize: 28,
      lineHeight: '36px',
    },
  },
  text: {
    fontSize: 18,
    lineHeight: '26px',
    fontWeight: 400,
    minWidth: 501,
    color: theme.palette.text.desk,
    textAlign: ({ mainAxis, isTabletOrMobile }: IStyleProps) =>
      mainAxis === 'column' || isTabletOrMobile ? 'center' : 'inherit',
    [theme.breakpoints.md]: {
      minWidth: 401,
    },
    [theme.breakpoints.sm]: {
      minWidth: 'auto',
    },
  },
}));

function Section({
  image,
  title,
  text,
  mainAxis = 'row',
  icon,
  imageNode,
  className,
  negativePadding = 0,
  children,
  imageSide = 'left',
  background,
  runAnimationImmediately = false,
}: IProps) {
  const { isTabletOrMobile, isMobile, isLargeScreen } = useDimension();
  const ref = useRef<HTMLDivElement>(null);
  const [animate, setAnimate] = useState(runAnimationImmediately);

  useScrollListener(
    ref,
    () => setAnimate(true),
    negativePadding + (isLargeScreen ? 150 : 0)
  );

  const childProps: IChildProps = {
    image,
    title,
    text,
    mainAxis,
    icon,
    imageNode,
    className,
    children,
    imageSide,
    background,
    animate,
    isMobile,
    negativePadding,
    isTabletOrMobile,
    rootRef: ref,
  };

  return mainAxis === 'row' ? (
    <HorizontalSection {...childProps} />
  ) : (
    <VerticalSection {...childProps} />
  );
}

export default Section;
