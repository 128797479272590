import { darken } from '@material-ui/core/styles';

const overrides = {
  root: {},
  outlined: {
    borderColor: '#1BB6C1',
    backgroundColor: '#1BB6C1',
    color: '#ffffff',
    fontSize: 12,
    fontWeight: 700,
    borderRadius: 0,
    '&:hover': {
      borderColor: darken('#1BB6C1', 0.2),
      backgroundColor: darken('#1BB6C1', 0.2),
    },
  },
};

export default overrides;
