import Axios from 'axios';
import { useFetchApi } from './';

const { REACT_APP_HEADER_ITEMS_URL = '', REACT_APP_HEADER_ICON_URL = '' } = process.env;

export function useHeaderRequest() {
    return useFetchApi(
        async () => Axios.get(REACT_APP_HEADER_ITEMS_URL), {}
    );
};

export async function getHeaderIcon(name: string): Promise<string> {
    const { data } = await Axios.get(`${REACT_APP_HEADER_ICON_URL}/${name}.svg`)

    return data as string
}
