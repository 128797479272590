import React from 'react';
import { NavLink } from 'react-router-dom';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

import Product from './Product';
import HeaderDropdown from '../HeaderDropdown';
import { getObjectValues } from '../../helpers';
import { PRIME_URL } from '../../consts';
import { AppRoutes } from '../../Routes';

interface IProps {
  items: any;
  open: boolean;
  onClose: () => void;
}

type ProductColumn = {
  title: string;
  links: Array<LinkItem>;
};

export type LinkItem = {
  desc: string;
  icon: string;
  title: string;
  iconLegacy: string;
  badge?: string;
  link?: string;
};

export const useStyles = makeStyles((theme: any) => ({
  navOpened: {
    display: 'flex !important',
  },
  navigation: {
    [theme.breakpoints.sm]: {
      width: '100%',
      paddingTop: 14,
      flexDirection: 'column',
      marginLeft: 0,
      marginBottom: 0,
      display: 'none',
    },
    marginLeft: 40,
    marginBottom: 4,
    display: 'flex',
    '& > li': {
      marginRight: 40,
      fontWeight: 600,
      [theme.breakpoints.sm]: {
        marginRight: 0,
      },
    },
  },
  productMenu: {
    left: -14,
  },
  linkT: {
    [theme.breakpoints.sm]: {
      borderTop: '1px solid #2f2f2f',
    },
  },
  servicesMenu: {
    [theme.breakpoints.sm]: {
      width: '100%',
      left: 'auto',
      transform: 'initial',
    },
    left: '50%',
    transform: 'translateX(-50%)',
    padding: 24,
    minWidth: 164,
    flexDirection: 'column',
    '& a': {
      textDecoration: 'none',
      color: '#ffffff',
      fontSize: 14,
      fontWeight: 'bold',
      lineHeight: '20px',
      '&:hover': {
        color: '#2bcbd7',
      },
      '&:not(:last-child)': {
        marginBottom: 16,
      },
    },
  },
  navItem: {
    [theme.breakpoints.sm]: {
      padding: '18px 22px',
      borderBottom: '1px solid #2f2f2f',
    },
    '& span': {
      fontSize: 14,
      lineHeight: '20px',
      color: '#ffffff',
      cursor: 'pointer',
      textDecoration: 'none',
      '&:hover': {
        color: '#2bcbd7',
      },
      [theme.breakpoints.sm]: {
        fontSize: 16,
        lineHeight: '24px',
      },
    },
  },
  productItem: {
    marginRight: 16,
    position: 'relative',
    '&:not(:first-child)': {
      paddingLeft: 32,
      marginRight: 16,
    },
    '&:not(:last-child)': {
      '&:before': {
        position: 'absolute',
        content: "''",
        top: 4,
        right: -16,
        width: 1,
        height: 'calc(100% - 4px)',
        background: '#2F2F2F',
      },
    },
    [theme.breakpoints.sm]: {
      marginRight: 0,
      '&:not(:first-child)': {
        paddingTop: 16,
        paddingLeft: 0,
        marginRight: 0,
      },
      '&:not(:last-child)': {
        paddingBottom: 16,
        '&:before': {
          '@media (max-width: 480px)': {
            height: '2px!important',
            width: 'calc(100% + 48px)!important',
            left: -24,
            bottom: 0,
            top: 'auto!important',
          },
          width: 'calc(100% + 96px)!important',
          height: '2px!important',
          left: -48,
          top: 'auto!important',
          bottom: 0,
        },
      },
    },
  },
  productItemTitle: {
    marginBottom: 20,
    fontWeight: 'bold',
    fontSize: 18,
    lineHeight: '26px',
    color: 'rgba(255, 255, 255, 0.6)',
    [theme.breakpoints.sm]: {
      fontWeight: 'bold',
      fontSize: 10,
      lineHeight: '14px',
    },
  },
  productBlock: {
    '&:last-child': {
      marginBottom: 0,
    },
  },
  links: {
    marginLeft: theme.spacing(3),
    display: 'flex',
    position: 'relative',
    '&:before': {
      content: '" "',
      position: 'absolute',
      left: -24,
      height: 24,
      width: 1,
      background: '#26374C',
    },
    [theme.breakpoints.sm]: {
      marginLeft: 0,
      flexDirection: 'column',
      '&:before': {
        content: 'none',
      },
    },
    '& a:not(:last-child)': {
      marginRight: theme.spacing(4),
      [theme.breakpoints.sm]: {
        marginRight: 0,
      },
    },
    '& a': {
      fontSize: 14,
      lineHeight: '22px',
      letterSpacing: '0.25px',
      textDecoration: 'none',
      fontWeight: 600,
      color: '#ffffff',
      '&.active': {
        color: '#1BB6C1',
      },
      [theme.breakpoints.sm]: {
        paddingLeft: 42,
        fontSize: 16,
        borderBottom: '1px solid #2f2f2f',
        lineHeight: '24px',
        paddingTop: 16,
        paddingBottom: 16,
        '&:first-child': {
          borderTop: '1px solid #2f2f2f',
        },
      },
      [theme.breakpoints.xs]: {
        paddingLeft: 22,
      },
    },
  },
}));

function Navigation({ items, open, onClose }: IProps) {
  const classes = useStyles();

  const normalizedProducts: ProductColumn[] = items.map(
    (item: ProductColumn) => ({
      title: item.title,
      links: getObjectValues(item.links) as unknown as LinkItem[],
    })
  );

  return (
    <nav className={clsx(classes.navigation, { [classes.navOpened]: open })}>
      <HeaderDropdown
        title="Prime"
        classNameMenu={clsx({
          [classes.productMenu]: !!normalizedProducts.length,
        })}
      >
        {normalizedProducts.map((product: ProductColumn) => (
          <li key={product.title} className={classes.productItem}>
            <h6 className={classes.productItemTitle}>{product.title}</h6>
            {product.links.map((item: LinkItem) => (
              <Product
                key={item.title}
                product={item}
                isPrime={item.link === PRIME_URL}
                className={classes.productBlock}
              />
            ))}
          </li>
        ))}
      </HeaderDropdown>
      <div className={classes.links}>
        <NavLink to={AppRoutes.root} exact onClick={onClose}>
          Home
        </NavLink>
        <NavLink to={AppRoutes.solutions} onClick={onClose}>
          Solutions
        </NavLink>
        <NavLink to={AppRoutes.features} onClick={onClose}>
          Features
        </NavLink>
        <NavLink to={AppRoutes.markets} onClick={onClose}>
          Markets
        </NavLink>
        <NavLink to={AppRoutes.staking} onClick={onClose}>
          Staking
        </NavLink>
        <NavLink to={AppRoutes.about} onClick={onClose}>
          About us
        </NavLink>
      </div>
    </nav>
  );
}

export default Navigation;
