import React, { ChangeEvent, useState } from 'react';
import Big from 'big.js';
import clsx from 'clsx';
import {
  Typography,
  Slider,
  Select,
  MenuItem,
  Input,
  InputAdornment,
  Button,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CurrencyIcon from '../../components/UI/CurrencyIcon';
import { DEFAULT_PRECISION } from '../../consts';
import type { AssetType } from './hooks/useGetStakingAssets';

const useStyles = makeStyles((theme: any) => ({
  container: {
    width: 480,
    padding: '41px 32px 32px 32px',
    backgroundColor: theme.palette.background.light,
  },
  heading: {
    marginBottom: theme.spacing(4),
  },
  flexBetween: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  largeText: {
    fontSize: 24,
    fontWeight: 700,
    lineHeight: '32px',
  },
  secondaryText: {
    fontSize: 14,
    lineHeight: '24px',
    color: 'rgba(255,255,255,0.6)',
  },
  infoBox: {
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(1.5),
  },
  currency: {
    marginLeft: theme.spacing(0.5),
    fontSize: 16,
    fontWeight: 400,
    lineHeight: '24px',
  },
  menuItemContainer: {
    '&:hover': {
      backgroundColor: theme.palette.background.light + '!important',
    },
    '&:not(:last-child)': {
      borderBottom: `1px solid rgba(255,255,255, 0.2)`,
    },
    '&:not(:first-child)': {
      paddingTop: 8,
    },
  },
  select: {
    width: 170,
  },
  menuItem: {
    marginRight: 44,
    display: 'flex',
    '& img': {
      marginRight: theme.spacing(1),
    },
    '& span': {
      fontSize: 18,
      lineHeight: '26px',
      color: '#ffffff',
    },
  },
  apy: {
    color: theme.palette.text.green,
  },
  slider: {
    color: theme.palette.link.main,
    '& .MuiSlider-rail, .MuiSlider-track': {
      height: 5,
    },
    '& .MuiSlider-rail': {
      backgroundColor: '#ffffff',
      opacity: 1,
    },
    '& .MuiSlider-thumb': {
      marginTop: -8,
      backgroundColor: '#ffffff',
      boxSizing: 'content-box',
      border: `4px solid ${theme.palette.link.main}`,
    },
  },
  rewardList: {
    marginBottom: theme.spacing(4),
    '& li': {
      display: 'flex',
      justifyContent: 'space-between',

      '&:not(:last-child)': {
        paddingBottom: theme.spacing(2),
        borderBottom: '1px solid rgba(255, 255, 255, 0.2)',
      },
      '&:not(:first-child)': {
        paddingTop: theme.spacing(2),
      },
    },
  },
  rewardValues: {
    display: 'flex',
    alignItems: 'center',
    '& p': {
      marginRight: theme.spacing(1),
      fontSize: 16,
      fontWeight: 700,
      lineHeight: '24px',
    },
    '& span': {
      fontSize: 16,
      fontWeight: 400,
      lineHeight: '24px',
      color: 'rgba(255, 255, 255, 0.6)',
    },
  },
  input: {
    width: 180,
    color: '#ffffff',
    fontSize: 24,
    fontWeight: 700,
    '& > input[type="number"]': {
      '-moz-appearance': 'textfield',
    },
    '& > input::-webkit-outer-spin-button, input::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
    },
  },
  btnRequest: {
    margin: '0 auto',
    padding: '8px 46px',
    display: 'block',
    fontSize: 12,
    fontWeight: 700,
  },
}));

interface IProps {
  availableAssets: AssetType[];
  handleChangeAsset: (selectedAsset: AssetType) => void;
  handleStakingRequest: (amount?: string) => void;
  currentAsset?: AssetType;
}

function StakeWidget({
  availableAssets,
  currentAsset,
  handleChangeAsset,
  handleStakingRequest,
}: IProps) {
  const classes = useStyles();
  const [value, setValue] = useState('1200');

  function handleChange(e: React.ChangeEvent<{}>, newValue: number | number[]) {
    setValue(newValue.toString());
  }

  function handleAmountChange(e: ChangeEvent<HTMLInputElement>) {
    const newAmount = e.target.value.includes(',')
      ? e.target.value.replace(',', '.')
      : e.target.value;
    const [solid = '', fraction = ''] = newAmount.split('.');
    if (!isNaN(+newAmount)) {
      if (
        +solid <= 1_000_000 &&
        +solid >= 0 &&
        fraction.length <= (currentAsset?.uiPrecision ?? 8)
      ) {
        if (
          (solid.startsWith('0') && solid.length > 1) ||
          (currentAsset?.uiPrecision === 0 && newAmount.includes('.'))
        ) {
          return;
        }
        setValue(newAmount);
      }
    }
  }

  function calculateReward(period: 1 | 31 | 365) {
    if (currentAsset) {
      return Big(currentAsset.stackingAPY)
        .div(100)
        .mul(
          Big(period)
            .div(365)
            .mul(Big(value || 0))
        )
        .round(currentAsset.uiPrecision || DEFAULT_PRECISION, Big.roundDown)
        .toString();
    }

    return 'N/A';
  }

  return (
    <section className={classes.container}>
      <div className={clsx(classes.flexBetween, classes.heading)}>
        <Typography className={classes.largeText} component="p">
          Choose a coin
        </Typography>
        <Select
          variant="outlined"
          value={currentAsset?.currency}
          className={classes.select}
          MenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
            getContentAnchorEl: null,
          }}
          onChange={(e) => {
            const selectedAsset = availableAssets.find(
              (a) => a.currency === e.target.value
            )!;
            handleChangeAsset(selectedAsset);
          }}
        >
          {availableAssets.map((asset) => (
            <MenuItem
              className={classes.menuItemContainer}
              key={asset.currency}
              value={asset.currency}
            >
              <div className={classes.menuItem}>
                <CurrencyIcon title={asset.currency} />
                <Typography component="span">{asset.currency}</Typography>
              </div>
            </MenuItem>
          ))}
        </Select>
      </div>
      <div className={classes.flexBetween}>
        <Typography className={classes.secondaryText}>
          How many coin do you hold?
        </Typography>
        <Typography className={classes.secondaryText}>
          Estimated Annual Yield:
        </Typography>
      </div>
      <div className={clsx(classes.flexBetween, classes.infoBox)}>
        <Input
          type="number"
          value={value}
          className={classes.input}
          onChange={handleAmountChange}
          disableUnderline
          endAdornment={
            <InputAdornment position="end" className={classes.currency}>
              {currentAsset?.currency}
            </InputAdornment>
          }
        />
        <Typography className={clsx(classes.largeText, classes.apy)}>
          {currentAsset?.stackingAPY}%
        </Typography>
      </div>
      <Slider
        className={classes.slider}
        value={+value}
        min={0}
        max={1_000_000}
        onChange={handleChange}
        aria-labelledby="staking-amount-slider"
      />
      <ul className={classes.rewardList}>
        <li>
          <Typography className={classes.secondaryText}>
            Daily Earnings:
          </Typography>
          <div className={classes.rewardValues}>
            <Typography component="p">
              {calculateReward(1)} {currentAsset?.currency}
            </Typography>
            {/* <Typography component="span">4.11 USD</Typography> */}
          </div>
        </li>
        <li>
          <Typography className={classes.secondaryText}>
            Monthly Earnings:
          </Typography>
          <div className={classes.rewardValues}>
            <Typography component="p">
              {calculateReward(31)} {currentAsset?.currency}
            </Typography>
            {/* <Typography component="span">4.11 USD</Typography> */}
          </div>
        </li>
        <li>
          <Typography className={classes.secondaryText}>
            Yearly Earnings:
          </Typography>
          <div className={classes.rewardValues}>
            <Typography component="p">
              {calculateReward(365)} {currentAsset?.currency}
            </Typography>
            {/* <Typography component="span">4.11 USD</Typography> */}
          </div>
        </li>
      </ul>
      <Button
        variant="outlined"
        className={classes.btnRequest}
        onClick={() => handleStakingRequest(value)}
      >
        Request Staking
      </Button>
    </section>
  );
}

export default StakeWidget;
