import palette from '../palette';

const overrides = {
  paper: {
    marginTop: 8,
    backgroundColor: '#000000',
  },
  root: {
    '& > .MuiListItem-root': {
      borderBottom: `1px solid ${palette.border.gray}`,
    },
  },
};

export default overrides;
