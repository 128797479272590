import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, MenuItem, Select, Typography } from '@material-ui/core';
import * as yup from 'yup';
import InputOutlined from '../../components/Form/InputOutlined';
import { AssetType } from './hooks/useGetStakingAssets';
import CurrencyIcon from '../../components/UI/CurrencyIcon';
import { StakingRequest } from '../../typings';

const schema = yup
  .object({
    fullName: yup.string().required('Full Name is required field'),
    companyName: yup.string().required('Company Name is required field'),
    email: yup.string().email().required('Email is required field'),
    phoneNumber: yup.string().required('Phone number is required field'),
    currency: yup.string().required('Currency is required field'),
    amount: yup.number().required('Amount is required field'),
    message: yup.string(),
  })
  .required();

type FormData = yup.InferType<typeof schema>;

const useStyles = makeStyles((theme: any) => ({
  grid: {
    marginBottom: theme.spacing(3),
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: '24px',
  },
  field: {
    marginBottom: theme.spacing(3),
  },
  policy: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(4),
    fontSize: 14,
    lineHeight: '20px',
    textAlign: 'center',
    '& a': {
      color: '#1BB6C1',
    },
  },
  btnSubmit: {
    margin: '0 auto',
    display: 'block',
    padding: '17px 93px',
    fontSize: 18,
    lineHeight: '26px',
  },
  currencyTitle: {
    marginBottom: theme.spacing(1),
    fontSize: 12,
    lineHeight: '18px',
  },
  currencySelect: {
    minHeight: 45,
    '& .MuiSelect-outlined': {
      padding: '9.5px 16px',
    },
    '& .MuiSelect-selectMenu': {
      backgroundColor: 'transparent',
    },
  },
  menuItemContainer: {
    '&:hover': {
      backgroundColor: theme.palette.background.light + '!important',
    },
    '&:not(:last-child)': {
      borderBottom: `1px solid rgba(255,255,255, 0.2)`,
    },
    '&:not(:first-child)': {
      paddingTop: 8,
    },
  },
  menuItem: {
    marginRight: 44,
    display: 'flex',
    '& img': {
      marginRight: theme.spacing(1),
    },
    '& p, span': {
      marginTop: 1,
      fontSize: 14,
      lineHeight: '20px',
      color: '#ffffff',
    },
    '& span': {
      marginLeft: 4,
      color: '#999999',
    },
  },
}));

interface IProps {
  availableAssets: AssetType[];
  handleRequest: (params: StakingRequest) => void;
  submitting: boolean;
  result?: string;
  preselectAsset?: AssetType;
}

function ContactForm({
  availableAssets,
  preselectAsset,
  handleRequest,
  result,
  submitting,
}: IProps) {
  const classes = useStyles();
  const {
    handleSubmit,
    control,
    setValue,
    reset,
    formState: { errors },
  } = useForm<FormData>({
    resolver: yupResolver(schema),
    defaultValues: {
      currency: preselectAsset?.currency || '',
      companyName: '',
      email: '',
      fullName: '',
      message: '',
      phoneNumber: '',
    },
  });

  function onSubmit(formData: FormData) {
    handleRequest({
      type: 'touch',
      firstname: formData.fullName,
      email: formData.email,
      phone: formData.phoneNumber,
      company: formData.companyName,
      message: formData.message || '',
      currency: formData.currency,
      amount: formData.amount,
    });
  }

  useEffect(() => {
    if (result === 'success') {
      reset();
      if (preselectAsset) {
        setValue('currency', preselectAsset.currency);
      }
    }
  }, [result]);

  useEffect(() => {
    if (preselectAsset) {
      setValue('currency', preselectAsset.currency);
    }
  }, [preselectAsset]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className={classes.grid}>
        <Controller
          name="fullName"
          control={control}
          render={({ field: { onChange, value } }) => (
            <InputOutlined
              onChange={onChange}
              value={value}
              label="Full name"
              helperText={errors?.fullName?.message}
              variant="outlined"
              fullWidth
              placeholder="Enter your name"
              error={!!errors?.fullName?.message}
            />
          )}
        />
        <Controller
          name="companyName"
          control={control}
          render={({ field: { onChange, value } }) => (
            <InputOutlined
              onChange={onChange}
              value={value}
              fullWidth
              label="Company name"
              helperText={errors?.fullName?.message}
              variant="outlined"
              placeholder="Enter name of your company"
              error={!!errors?.fullName?.message}
            />
          )}
        />
      </div>
      <div className={classes.grid}>
        <Controller
          name="email"
          control={control}
          render={({ field: { onChange, value } }) => (
            <InputOutlined
              onChange={onChange}
              value={value}
              fullWidth
              label="Company email"
              helperText={errors?.email?.message}
              variant="outlined"
              placeholder="Enter email of your company"
              error={!!errors?.email?.message}
            />
          )}
        />
        <Controller
          name="phoneNumber"
          control={control}
          render={({ field: { onChange, value } }) => (
            <InputOutlined
              onChange={onChange}
              value={value}
              fullWidth
              type="tel"
              label="Phone number"
              helperText={errors?.phoneNumber?.message}
              variant="outlined"
              placeholder="Enter phone number"
              error={!!errors?.phoneNumber?.message}
            />
          )}
        />
      </div>
      <div className={classes.grid}>
        <Controller
          name="currency"
          control={control}
          render={({ field: { onChange, value } }) => (
            <div>
              <Typography className={classes.currencyTitle} component="p">
                Currency
              </Typography>
              <Select
                onChange={(e) => {
                  const selectedAsset = availableAssets.find(
                    (a) => a.currency === e.target.value
                  )!;
                  onChange(selectedAsset.currency);
                }}
                value={value}
                variant="outlined"
                fullWidth
                className={classes.currencySelect}
                error={!!errors?.currency?.message}
                MenuProps={{
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                  transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left',
                  },
                  getContentAnchorEl: null,
                }}
              >
                {availableAssets.map((asset) => (
                  <MenuItem
                    className={classes.menuItemContainer}
                    key={asset.currency}
                    value={asset.currency}
                  >
                    <div className={classes.menuItem}>
                      <CurrencyIcon title={asset.currency} />
                      <Typography component="p">
                        {asset.name}
                        <Typography component="span">
                          {asset.currency}
                        </Typography>
                      </Typography>
                    </div>
                  </MenuItem>
                ))}
              </Select>
            </div>
          )}
        />
        <Controller
          name="amount"
          control={control}
          render={({ field: { onChange, value } }) => (
            <InputOutlined
              onChange={onChange}
              value={value}
              fullWidth
              label="Stake amount"
              helperText={errors?.amount?.message}
              variant="outlined"
              placeholder="Enter stake amount"
              error={!!errors?.amount?.message}
            />
          )}
        />
      </div>
      <Controller
        name="message"
        control={control}
        render={({ field: { onChange, value } }) => (
          <InputOutlined
            onChange={onChange}
            value={value}
            fullWidth
            multiline
            rows={3}
            className={classes.field}
            label="Your message"
            helperText={errors?.message?.message}
            variant="outlined"
            placeholder="Enter your message"
            error={!!errors?.message?.message}
          />
        )}
      />
      <Typography className={classes.policy}>
        Your personal data will be processed in accordance with{' '}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://cex.io/privacy"
        >
          CEX.IO policy
        </a>
      </Typography>
      <Button
        disabled={submitting}
        className={classes.btnSubmit}
        size="large"
        type="submit"
        variant="outlined"
      >
        Submit
      </Button>
    </form>
  );
}

export default ContactForm;
