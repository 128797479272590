import React from 'react';

interface IProps {
  color?: string;
}

export default function PlusIcon({ color = 'white' }: IProps) {
  return (
    <svg
      width="64"
      height="58"
      viewBox="0 0 64 58"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M53.902 21.8035H64L32 0.2677L0 21.8035H10.098V47.3558H3.37363V57.7305H60.6263V47.3558H53.902V21.8035ZM51.8527 47.3558H45.5768V21.8035H51.8527V47.3558ZM26.8126 47.3558H20.4726V21.8035H26.8126V47.3558ZM28.862 21.8035H35.138V47.3558H28.862V21.8035ZM37.1874 21.8035H43.5274V47.3558H37.1874V21.8035ZM6.71537 19.7541L32 2.7372L57.2846 19.7541H6.71537ZM12.1473 21.8035H18.4233V47.3558H12.1473V21.8035ZM58.577 49.4051V55.6811H5.423V49.4051H58.577Z"
        fill="#1BB6C1"
      />
      <path
        d="M15.9492 16.9364L32.0005 6.13391L48.0517 16.9364H15.9492Z"
        fill="#1BB6C1"
        fillOpacity="0.1"
      />
    </svg>
  );
}
