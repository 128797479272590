// @ts-nocheck
import { createTheme } from '@material-ui/core/styles';
import breakpoints from './breakpoints';
import palette from './palette';
import overrides from './overrides';

const DEFAULT_MARGIN = 8;
export default createTheme({
  breakpoints,
  palette,
  spacing: (value: number) => `${value * DEFAULT_MARGIN}px`,
  overrides,
});
