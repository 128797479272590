import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { NavLink, useLocation } from 'react-router-dom';
import Logo from '../Logo';
import Button from '../Button';
import useGlobalStyles from '../../theme/useGlobalStyles';
import { getCurrentUTCDate } from '../../helpers';
import FacebookIcon from '../../static/images/fb_icon.svg';
import TwitterIcon from '../../static/images/twitter_icon.svg';
import LinkedinIcon from '../../static/images/linkedin_icon.svg';
import { AppRoutes } from '../../Routes';

const useStyles = makeStyles((theme: any) => ({
  root: {
    padding: '40px 150px',
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.md]: {
      padding: theme.spacing(5),
    },
    [theme.breakpoints.sm]: {
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5),
    },
    [theme.breakpoints.xs]: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      flexDirection: 'column-reverse',
      justifyContent: 'flex-start',
    },
  },
  smallBottomPadding: {
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.md]: {
      paddingBottom: theme.spacing(3),
    },
  },
  serverTime: {
    marginTop: 8,
    fontSize: 14,
    lineHeight: '26px',
    color: '#7A7A7A',
    textAlign: 'center',
    [theme.breakpoints.xs]: {
      textAlign: 'left',
    },
  },
  rightSide: {
    display: 'flex',
    flexFlow: 'row wrap',
    justifyContent: 'flex-end',
    [theme.breakpoints.sm]: {
      flexFlow: 'row',
    },
    [theme.breakpoints.xs]: {
      marginBottom: 40,
      width: '100%',
      display: 'grid',
      gridTemplateColumns: '1fr 1fr 1fr',
      gap: '0px 20px',
    },
  },
  menuCol: {
    marginLeft: 80,
    [theme.breakpoints.xs]: {
      marginLeft: 0,
      width: 'max-content',
      '&:nth-child(2)': {
        paddingLeft: theme.spacing(2),
      },
    },
    '& .title': {
      fontSize: 18,
      lineHeight: '26px',
      color: '#767676',
      marginBottom: 14,
    },
  },
  menu: {
    '& li': {
      marginBottom: 8,
    },
    '& a, span': {
      color: theme.palette.text.white,
      fontSize: 14,
      lineHeight: '24px',
      textDecoration: 'none',
      transition: 'color 300ms ease 0s',
      cursor: 'pointer',
      '&:hover': {
        color: theme.palette.link.main,
      },
    },
  },
  logo: {
    '& img': {
      width: 225,
      height: 40,
    },
  },
  btnStatus: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(4),
    marginLeft: theme.spacing(1),
    [theme.breakpoints.xs]: {
      marginLeft: 0,
      width: '100%',
    },
  },
  socials: {
    marginLeft: theme.spacing(3),
    display: 'flex',
    '& li:not(:last-child)': {
      marginRight: theme.spacing(3.5),
    },
    [theme.breakpoints.xs]: {
      marginLeft: 0,
      justifyContent: 'center',
    },
  },
  disclaimer: {
    padding: '0 150px 40px 150px',
    [theme.breakpoints.md]: {
      padding: theme.spacing(5),
      paddingTop: 0,
    },
    [theme.breakpoints.sm]: {
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5),
    },
    [theme.breakpoints.xs]: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
    ' & p': {
      color: '#7A7A7A',
      fontSize: 14,
      lineHeight: '20px',
    },
    '& a': {
      color: '#7A7A7A',
      textDecoration: 'none',
      fontSize: 14,
      lineHeight: '20px',
    },
    '& li': {
      marginTop: theme.spacing(1),
      marginLeft: theme.spacing(2),
      color: '#7A7A7A',
      listStyle: 'disc',
    },
  },
  disclaimerChild: {
    paddingTop: theme.spacing(2),
    borderTop: '1px solid #7A7A7A',
    '& p:not(last-child)': {
      marginBottom: theme.spacing(1),
    }
  },
}));

export default function Footer() {
  const classes = useStyles();
  const globalClasses = useGlobalStyles();
  const { pathname } = useLocation();
  const showDisclaimer = [AppRoutes.root, AppRoutes.about].includes(
    pathname as AppRoutes
  );

  const mainPageDisclaimer = (
    <div className={classes.disclaimerChild}>
      <p>
        <a
          href={process.env.REACT_APP_CEX_URL}
          target="_blank"
          rel="noopener noreferrer"
        >
          {' '}
          CEX.IO{' '}
        </a>
        <span>
          EU VASP, UAB (306186479) is managing this Website and is registered as
          the Virtual Asset Service Provider (VASP) and depository virtual currency
          wallets operator in the State Enterprise Centre of Registers of the Republic of
          Lithuania lists. The company’s registered office address is at Didžioji
          str.18, Vilnius, Lithuania.
        </span>
      </p>
      <p>
        <a
          href={process.env.REACT_APP_CEX_URL}
          target="_blank"
          rel="noopener noreferrer"
        >
          {' '}
          CEX.IO{' '}
        </a>
        owns and operates entities across jurisdictions, with services tailored
        to users from the respective jurisdictions based upon a variety of
        factors, including local regulatory requirements. For more information
        on our legal entities, which cover any and all of the services on our
        platform, please visit:
      </p>
      <ul>
        <li>
          <a
            href={process.env.REACT_APP_CEX_LEGAL_URL}
            target="_blank"
            rel="noopener noreferrer"
          >
            CEX.IO Licenses and Registrations
          </a>
        </li>
        <li>
          <a
            href={process.env.REACT_APP_CEX_TERMS_URL}
            target="_blank"
            rel="noopener noreferrer"
          >
            Terms of use - CEX.IO
          </a>
        </li>
      </ul>
    </div>
  );

  const aboutPageDisclaimer = (
    <div className={classes.disclaimerChild}>
      <p>
        <a
          href={process.env.REACT_APP_CEX_URL}
          target="_blank"
          rel="noopener noreferrer"
        >
          {' '}
          CEX.IO{' '}
        </a>
        <span>
          EU VASP, UAB (306186479) is managing this Website and is registered as
          the Virtual Asset Service Provider (VASP) and depository virtual currency
          wallets operator in the State Enterprise Centre of Registers of the Republic of
          Lithuania lists. The company’s registered office address is at Didžioji
          str.18, Vilnius, Lithuania.
        </span>
      </p>
      <p>
        The availability of the products, features, and services on the
        <a
          href={process.env.REACT_APP_CEX_URL}
          target="_blank"
          rel="noopener noreferrer"
        >
          {' '}
          CEX.IO{' '}
        </a>
        platform is subject to jurisdictional limitations.
        <a
          href={process.env.REACT_APP_CEX_URL}
          target="_blank"
          rel="noopener noreferrer"
        >
          {' '}
          CEX.IO{' '}
        </a>
        may not offer certain products, features, and services on the
        <a
          href={process.env.REACT_APP_CEX_URL}
          target="_blank"
          rel="noopener noreferrer"
        >
          {' '}
          CEX.IO{' '}
        </a>
        platform in certain jurisdictions due to potential or actual regulatory
        restrictions. For more information, see our
        <a
          href={process.env.REACT_APP_CEX_LEGAL_URL}
          target="_blank"
          rel="noopener noreferrer"
        >
          {' '}
          Licenses and Registrations.
        </a>
      </p>
    </div>
  );

  return (
    <>
      <div
        className={clsx(globalClasses.container, classes.root, {
          [classes.smallBottomPadding]: showDisclaimer,
        })}
      >
        <div>
          <Logo className={classes.logo} />
          <div>
            <p className={classes.serverTime}>
              Server time: {getCurrentUTCDate()}
            </p>
            <Button
              className={classes.btnStatus}
              component="a"
              external
              href="https://status.prime.cex.io/"
            >
              cex.io prime status
            </Button>
            <ul className={classes.socials}>
              <li>
                <a
                  href="https://twitter.com/cex_io/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={TwitterIcon} alt="twitter_official_account" />
                </a>
              </li>
              <li>
                <a
                  href="https://www.facebook.com/CEX.IO/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={FacebookIcon} alt="facebook_official_account" />
                </a>
              </li>
              <li>
                <a
                  href="https://www.linkedin.com/company/cex-io/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={LinkedinIcon} alt="linkedin_official_account" />
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className={classes.rightSide}>
          <div className={classes.menuCol}>
            <div className="title">Information</div>
            <ul className={classes.menu}>
              <li>
                <NavLink to={AppRoutes.solutions}>Solutions</NavLink>
              </li>
              <li>
                <NavLink to={AppRoutes.features}>Features</NavLink>
              </li>
              <li>
                <NavLink to={AppRoutes.markets}>Markets</NavLink>
              </li>
            </ul>
          </div>
          <div className={classes.menuCol}>
            <div className="title">Tools</div>
            <ul className={classes.menu}>
              <li>
                <a
                  href="https://docs.prime.cex.io/#introduction"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  API
                </a>
              </li>
            </ul>
          </div>
          <div className={classes.menuCol}>
            <div className="title">About</div>
            <ul className={classes.menu}>
              <li>
                <NavLink to={AppRoutes.about}>About us</NavLink>
              </li>
              <li>
                <a
                  href="https://cex.io/privacy"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Privacy Policy
                </a>
              </li>
              <li>
                <a
                  href={process.env.REACT_APP_CEX_TERMS_URL}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Terms of Service
                </a>
              </li>
              <li>
                <a
                  href="https://support.prime.cex.io/en/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Help Centre
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      {showDisclaimer && (
        <div className={clsx(globalClasses.container, classes.disclaimer)}>
          {pathname === AppRoutes.root
            ? mainPageDisclaimer
            : aboutPageDisclaimer}
        </div>
      )}
    </>
  );
}
