import React from 'react';

interface IProps {
  color?: string;
}

export default function PlusIcon({ color = 'white' }: IProps) {
  return (
    <svg
      width="64"
      height="65"
      viewBox="0 0 64 65"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_6712_21264)">
        <path
          d="M48.8242 9.7518H51.3417V56.5406H48.8242V9.7518Z"
          fill="#1BB6C1"
          fillOpacity="0.1"
        />
        <path
          d="M14.8125 35.3993H17.33V56.5406H14.8125V35.3993Z"
          fill="#1BB6C1"
          fillOpacity="0.1"
        />
        <path
          d="M31.8164 25.1393H34.3339V56.5393H31.8164V25.1393Z"
          fill="#1BB6C1"
          fillOpacity="0.1"
        />
        <path
          d="M56.25 59.3781V4.85431H43.9212V59.3781H39.2388V20.2368H26.915V59.3781H22.2325V30.4956H9.90875V59.3781H5.22625V0.604309H3.16125V10.3718H0V12.4368H3.16125V27.3781H0V29.4431H3.16125V44.3843H0V46.4493H3.16125V61.4493H15.0363V64.6043H17.1012V61.4431H32.0438V64.6043H34.1087V61.4431H49.05V64.6043H51.115V61.4431H64V59.3781H56.25ZM11.9788 59.3781V32.5606H20.1737V59.3781H11.9788ZM28.9788 59.3781V22.3018H37.1738V59.3781H28.9788ZM45.9787 59.3781V6.91306H54.1738V59.3781H45.9787Z"
          fill="#1BB6C1"
        />
      </g>
      <defs>
        <clipPath id="clip0_6712_21264">
          <rect
            width="64"
            height="64"
            fill="white"
            transform="translate(0 0.604309)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
