import React from 'react';
import { Grow } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Section from '../../components/Section';
import ContentBox from '../../components/ContentBox';

import SubAccountsImage from '../../static/images/bg_sub_accounts.png';
import UserManagementImage from '../../static/images/bg_user_management.png';
import OrderTypesImage from '../../static/images/bg_order_types.png';
import DepositBalanceImage from '../../static/images/bg_deposit_balance.png';
import ReportingImage from '../../static/images/bg_reporting.png';
import UsersWidget from '../../static/images/users_widget.png';
import useDimension from '../../hooks/useDimension';

const useStyles = makeStyles((theme: any) => ({
  container: {
    overflowX: 'hidden',
  },
  topPanel: {
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(9),
    background: 'linear-gradient(180deg, #000000 0%, #0A0909 100%)',
    textAlign: 'center',
    '& h1': {
      fontSize: 72,
      fontWeight: 400,
      lineHeight: '84px',
      textTransform: 'uppercase',
    },
    '& p': {
      fontSize: 18,
      lineHeight: '26px',
      color: theme.palette.text.desk,
    },
    [theme.breakpoints.sm]: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
      '& h1': {
        marginBottom: theme.spacing(2),
        fontSize: 64,
        lineHeight: '72px',
      },
      '& p': {
        maxWidth: 592,
        margin: '0 auto',
      },
    },
    [theme.breakpoints.xs]: {
      '& h1': {
        fontSize: 40,
        lineHeight: '48px',
      },
      '& p': {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
      },
    },
  },
  subAccountsSection: {
    width: '100%',
    marginTop: theme.spacing(10),
    '& .section-content': {
      marginLeft: 150,
    },
    '& .section-text': {
      maxWidth: 509,
    },
    '& .section-image': {
      height: 407,
      width: 'auto',
    },
    [theme.breakpoints.md]: {
      '& .section-content': {
        marginLeft: theme.spacing(10),
      },
      '& .section-image': {
        marginRight: theme.spacing(4),
      },
    },
    [theme.breakpoints.sm]: {
      paddingRight: 0,
      paddingTop: 0,
      paddingLeft: 0,
      paddingBottom: theme.spacing(4),
      '& .section-content': {
        marginLeft: 0,
        paddingRight: 0,
      },
      '& .section-title': {
        fontSize: 36,
        lineHeight: '44px',
      },
      '& .section-text': {
        maxWidth: 583,
      },
      '& .section-image': {
        paddingRight: 0,
        marginRight: 0,
      },
    },
    [theme.breakpoints.xs]: {
      paddingBottom: theme.spacing(1),
      paddingLeft: 0,
      '& .section-content': {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
      },
      '& .section-title': {
        fontSize: 28,
        lineHeight: '36px',
      },
      '& .section-image': {
        height: 329,
        paddingRight: 0,
        width: 'auto',
        marginLeft: 210,
      },
    },
  },
  userManagementSection: {
    width: '100%',
    paddingBottom: 104,
    '& .section-content': {
      marginRight: 150,
    },
    '& .section-text': {
      maxWidth: 509,
    },
    '& .section-image': {
      height: 407,
      width: 'auto',
    },
    [theme.breakpoints.md]: {
      '& .section-content': {
        marginRight: theme.spacing(9),
      },
      '& .section-image': {
        marginLeft: theme.spacing(4),
      },
    },
    [theme.breakpoints.sm]: {
      paddingLeft: 0,
      paddingRight: 0,
      '& .section-content': {
        marginRight: 0,
      },
      '& .section-title': {
        fontSize: 36,
        lineHeight: '44px',
      },
      '& .section-text': {
        maxWidth: 635,
      },
      '& .section-image': {
        paddingLeft: 0,
        marginLeft: 0,
      },
    },
    [theme.breakpoints.xs]: {
      paddingRight: 0,
      '& .section-content': {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
      },
      '& .section-title': {
        fontSize: 28,
        lineHeight: '36px',
      },
      '& .section-image': {
        height: 329,
        width: 'auto',
        marginRight: 210,
        paddingLeft: 0,
      },
    },
  },
  userManagementImage: {
    marginRight: theme.spacing(8),
    height: 407,
    width: '100%',
    maxWidth: 697,
    position: 'relative',
    backgroundImage: `url(${UserManagementImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundPositionX: 'center',
    backgroundSize: 'contain',
    [theme.breakpoints.sm]: {
      order: 1,
      marginRight: 0,
    },
    [theme.breakpoints.xs]: {
      marginLeft: -45,
      height: 328,
      backgroundPositionX: 'right',
      backgroundSize: 'cover',
    },
  },
  userWidget: {
    display: 'none',
    [theme.breakpoints.sm]: {
      width: 200,
      height: 242,
      right: 160,
      bottom: -70,
      display: 'block',
      position: 'absolute',
    },
    [theme.breakpoints.xs]: {
      width: 170,
      height: 205,
      right: 75,
      bottom: -80,
    },
  },
  orderTypeSection: {
    paddingBottom: 60,
    width: '100%',
    backgroundColor: '#ffffff',
    justifyContent: 'flex-start',
    '& .section-title': {
      maxWidth: 500,
    },
    '& .section-text': {
      minWidth: 'initial',
      maxWidth: 470,
    },
    '& .section-image': {
      width: 'initial',
      maxWidth: 543,
      marginLeft: 113,
    },
    [theme.breakpoints.sm]: {
      paddingBottom: 0,
      '& .section-content': {
        paddingBottom: 0,
      },
      '& .section-image': {
        marginLeft: 0,
      },
      '& .section-title': {
        marginBottom: theme.spacing(2),
        maxWidth: 'inherit',
        fontSize: 36,
        lineHeight: '44px',
      },
      '& .section-text': {
        maxWidth: 620,
      },
    },
    [theme.breakpoints.xs]: {
      '& .section-image': {
        width: '100%',
      },
      '& .section-title': {
        fontSize: 36,
        lineHeight: '44px',
      },
    },
  },
  depositBalanceContainer: {
    marginTop: -2,
    backgroundColor: '#ffffff',
  },
  depositBalanceSection: {
    width: '100%',
    marginTop: 0,
    paddingBottom: 104,
    backgroundColor: '#ffffff',
    justifyContent: 'flex-end',
    '& .section-image': {
      marginRight: 86,
      width: 'initial',
      maxWidth: 543,
    },
    '& .section-text': {
      maxWidth: 620,
    },
    [theme.breakpoints.sm]: {
      paddingBottom: theme.spacing(6),
      '& .section-text': {
        maxWidth: 590,
      },
      '& .section-image': {
        marginRight: 0,
      },
    },
    [theme.breakpoints.xs]: {
      '& .section-image': {
        width: '100%',
      },
      '& .section-title': {
        fontSize: 36,
        lineHeight: '44px',
      },
    },
  },
  reportingSection: {
    width: '100%',
    marginTop: 104,
    paddingBottom: 104,
    '& .section-text': {
      maxWidth: 454,
      minWidth: 'auto',
    },
    '& .section-image': {
      width: 'max-content',
      maxWidth: 700,
      height: 407,
    },
    [theme.breakpoints.md]: {
      '& .section-content': {
        marginLeft: theme.spacing(8),
      },
      '& .section-image': {
        marginRight: theme.spacing(4),
      },
    },
    [theme.breakpoints.sm]: {
      marginTop: 0,
      paddingBottom: theme.spacing(6),
      '& .section-content': {
        marginLeft: 0,
      },
      '& .section-title': {
        fontSize: 36,
        lineHeight: '44px',
      },
      '& .section-text': {
        maxWidth: 663,
      },
      '& .section-image': {
        marginRight: 0,
        height: 415,
        width: '100%',
      },
    },
    [theme.breakpoints.xs]: {
      '& .section-image': {
        width: 500,
        height: 328,
        marginLeft: 185,
      },
    },
  },
}));

function Features() {
  const classes = useStyles();
  const { isLargeScreen } = useDimension();

  return (
    <main className={classes.container}>
      <Grow in timeout={2000}>
        <section className={classes.topPanel}>
          <h1>Features</h1>
          <p>
            CEX.IO Prime has all the investor tools needed to give you full
            control over your digital asset portfolio.
          </p>
        </section>
      </Grow>
      <ContentBox maxWidth={isLargeScreen ? '1920px' : 'inherit'}>
        <Section
          runAnimationImmediately
          className={classes.subAccountsSection}
          imageSide="right"
          title="Sub-accounts"
          text="Create and manage sub-accounts for different strategies, assets or your customers. Each sub-account has a segregated cryptocurrency wallet for easy management of funds. Automate your flow of funds with our sophisticated APIs."
          image={SubAccountsImage}
        />
      </ContentBox>
      <ContentBox maxWidth={isLargeScreen ? '1920px' : 'inherit'}>
        <Section
          className={classes.userManagementSection}
          title="User Management"
          text="Get your team onboarded and working together with CEX.IO Prime. We support multi-account access and user management with different access rights so you remain efficient, transparent and keep your operations secure."
          imageNode={
            <div className={classes.userManagementImage}>
              <img
                src={UsersWidget}
                alt="users_dropdown"
                className={classes.userWidget}
              />
            </div>
          }
        />
      </ContentBox>
      <ContentBox
        maxWidth={isLargeScreen ? '1920px' : '1132px'}
        className={classes.orderTypeSection}
      >
        <Section
          className={classes.orderTypeSection}
          imageSide="right"
          background="white"
          title="Variety of order types"
          text="Take advantage of CEX.IO Prime's variety of order types. Fill in your order instantly with Market order, or use a 
          set of advanced Limit orders that suit your strategy best."
          image={OrderTypesImage}
        />
      </ContentBox>
      <ContentBox
        maxWidth={isLargeScreen ? '1920px' : '1132px'}
        className={classes.depositBalanceContainer}
      >
        <Section
          className={classes.depositBalanceSection}
          background="white"
          title="Deposit balance"
          text="CEX.IO Prime’s deposit and withdrawal features make accessing and adding value to your account as simple as 
          a few clicks."
          image={DepositBalanceImage}
        />
      </ContentBox>
      <ContentBox
        maxWidth={isLargeScreen ? '1920px' : 'inherit'}
        background="#0D0D0D"
      >
        <Section
          className={classes.reportingSection}
          title="Reporting"
          imageSide="right"
          text="Control and track all operations within CEX.IO Prime with custom filters and easy exporting. Connect your colleagues as well so everyone is up-to-date."
          image={ReportingImage}
        />
      </ContentBox>
    </main>
  );
}

export default Features;
