import React from 'react';

function EmployeeIcon() {
  return (
    <svg
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5 59.25C6.80625 46.0162 18.1725 35.75 32 35.75C45.8275 35.75 57.195 46.0262 59 59.25H5Z"
        fill="#1BB6C1"
        fillOpacity="0.1"
      />
      <path
        d="M38.51 31.66C44.5666 29.0526 48.4931 23.094 48.5 16.5C48.5 7.40125 41.0988 0 32 0C22.9012 0 15.5 7.40125 15.5 16.5C15.5069 23.094 19.4334 29.0526 25.49 31.66C11.5437 34.5275 0 46.6175 0 64H64C64 46.7687 52.605 34.5588 38.51 31.66ZM17.5 16.5C17.5 8.49187 23.9919 2 32 2C40.0081 2 46.5 8.49187 46.5 16.5C46.5 24.5081 40.0081 31 32 31C23.9956 30.991 17.509 24.5044 17.5 16.5ZM31 33.0187V62H2.01625C2.52875 46.2388 15.1925 33.5325 31 33.0187ZM33 62V33.0187C48.8275 33.5337 61.4725 46.2575 61.9837 62H33Z"
        fill="#1BB6C1"
      />
      <path
        d="M39.6797 45.4062H47.8209V47.4062H39.6797V45.4062Z"
        fill="#1BB6C1"
      />
    </svg>
  );
}

export default EmployeeIcon;
