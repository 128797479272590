import React from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import CheckIcon from '../../static/images/check_icon.svg';

const useStyles = makeStyles((theme: any) => ({
  container: {
    padding: '40px 30px 0px 32px',
    backgroundColor: theme.palette.background.light,
  },
  title: {
    marginBottom: theme.spacing(2),
    fontSize: 24,
    fontWeight: 700,
    lineHeight: '32px',
  },
  content: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  list: {
    paddingRight: theme.spacing(4),
  },
  listItem: {
    marginBottom: theme.spacing(1),
    display: 'flex',
    alignItems: 'flex-start',
    '& img': {
      marginTop: 2,
      marginRight: 10,
    },
    '& span': {
      fontSize: 14,
      fontWeight: 700,
      lineHeight: '20px',
      color: 'rgba(255, 255, 255, 0.6)',
    },
  },
  image: {
    alignSelf: 'flex-end',
    width: 235,
    height: 200,
  },
}));

interface IProps {
  title: string;
  icon: string;
  text: string[];
}

function Offer({ title, icon, text }: IProps) {
  const classes = useStyles();

  return (
    <article className={classes.container}>
      <div className={classes.content}>
        <div>
          <Typography className={classes.title}>{title}</Typography>
          <ul className={classes.list}>
            {text.map((t, idx) => (
              <li className={classes.listItem} key={idx}>
                <img src={CheckIcon} alt="check_icon" />
                <Typography component="span">{t}</Typography>
              </li>
            ))}
          </ul>
        </div>
        <img src={icon} alt={`${icon}_img`} className={classes.image} />
      </div>
    </article>
  );
}

export default Offer;
