import React, { useEffect, useState, useCallback } from 'react';
import { Typography, Grow } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import Offer from './Offer';
import StakingAssetCard from '../../components/StakingAssetCard';
import BoxIcon from '../../static/images/box_icon.svg';
import DollarIcon from '../../static/images/dollar_icon.svg';
import TimeIcon from '../../static/images/time_icon.svg';
import OfferImage from '../../static/images/bg_exprnsive.png';
import BoxImage from '../../static/images/bg_savebox.png';
import ContactForm from './ContactForm';
import StakeWidget from './StakeWidget';
import RequestStakingModal from './RequestStakingModal';
import { useStyles, useGetStakingAssets } from './hooks';
import { useStakingRequest } from '../../api/staking';
import ResultPopup from '../Contacts/Popup';
import type { AssetType } from './hooks/useGetStakingAssets';
import type { StakingRequest } from '../../typings';

function Staking() {
  const classes = useStyles();
  const [asset, setAsset] = useState<AssetType>();
  const [preselectedAmount, setPreselectedAmount] = useState('');
  const [modal, setModal] = useState(false);
  const [result, setResult] = useState<string | undefined>();
  const {
    data: assets = [],
    fetchStakingAssets,
    loading: loadingAssets,
  } = useGetStakingAssets();
  const [submitRequest, submittingRequest] = useStakingRequest((res: any) => {
    setResult(res && res.ok && res.ok === 'ok' ? 'success' : 'error');
  });

  useEffect(() => {
    fetchStakingAssets();
  }, []);

  useEffect(() => {
    if (assets.length) {
      setAsset(assets[0]);
    }
  }, [assets]);

  const handleAssetClick = useCallback(function (item: AssetType) {
    setAsset(item);
    setModal(true);
  }, []);

  const handleModalOpen = useCallback(function (amount?: string) {
    setModal(true);
    if (amount) {
      setPreselectedAmount(amount);
    }
  }, []);

  const handleModalClose = useCallback(
    function () {
      setModal(false);
      if (preselectedAmount) {
        setPreselectedAmount('');
      }
    },
    [preselectedAmount]
  );

  return (
    <>
      <main className={classes.container}>
        <div className={classes.heading}>
          <Typography component="h5" className={classes.title}>
            Secure <span>Staking</span> for Businesses and Institutions Clients
          </Typography>
          <Typography className={classes.subtitle} component="p">
            CEX.IO Prime Staking ensures our institutional clients and
            businesses are getting the best returns and offset any inflationary
            impacts on capital via simple, secure, and flexible staking
            solutions. We meet the diverse needs of asset managers, family
            offices, hedge funds, OTC desks, banks, and more.
          </Typography>
        </div>
        <div className={classes.stakingAssets}>
          {!!assets.length &&
            assets.map((item) => {
              return (
                <StakingAssetCard
                  {...item}
                  onClick={() => handleAssetClick(item)}
                  key={item.currency}
                />
              );
            })}
          {loadingAssets &&
            Array(6)
              .fill(1)
              .map((_, idx) => (
                <Skeleton key={idx} variant="rect" height={108} />
              ))}
        </div>
        <div className={classes.widgetSection}>
          {asset ? (
            <StakeWidget
              availableAssets={assets}
              currentAsset={asset}
              handleChangeAsset={setAsset}
              handleStakingRequest={handleModalOpen}
            />
          ) : (
            <Skeleton variant="rect" width={480} height={408} />
          )}
          <div className={classes.widgetDescription}>
            <Typography component="h6" className={classes.widgetTitle}>
              Advantage: <span>Staking</span>
            </Typography>
            <Typography className={classes.widgetText}>
              In the last three years, the Decentralized Finance (DeFi) market
              cap grew from $700M to $200B. Millions of people, businesses, and
              institutions are generating yields that are by far higher than
              those in the traditional finance industry.
              <br />
              <br />
              As the crypto industry moves to greener proof of stake
              blockchains, we have new mechanisms in place. Ecosystem
              participants that stake their assets are contributing to the
              security of the network and are rewarded for that.
            </Typography>
          </div>
        </div>
        <Grow in={!!assets.length} timeout={2000}>
          <ul className={classes.advantagesSection}>
            <li className={classes.advantagesItem}>
              <img
                src={BoxIcon}
                alt="box_icon"
                className={classes.advantageIcon}
              />
              <div>
                <Typography component="p">50+</Typography>
                <Typography component="span">Nodes operated</Typography>
              </div>
            </li>
            <li className={classes.advantagesItem}>
              <img
                src={DollarIcon}
                alt="dollar_icon"
                className={classes.advantageIcon}
              />
              <div>
                <Typography component="p">$250M+</Typography>
                <Typography component="span">
                  In Assets Staked with CEX.IO
                </Typography>
              </div>
            </li>
            <li className={classes.advantagesItem}>
              <img
                src={TimeIcon}
                alt="time_icon"
                className={classes.advantageIcon}
              />
              <div>
                <Typography component="p">99.9%</Typography>
                <Typography component="span">
                  Availability and uptime.
                </Typography>
              </div>
            </li>
          </ul>
        </Grow>
        <Grow in={!!assets.length} timeout={2500}>
          <div className={classes.offeringSection}>
            <Typography component="h6" className={classes.offeringTitle}>
              Our offering
            </Typography>
            <section className={classes.offers}>
              <Offer
                title="Non-Custodial Staking"
                text={[
                  'Stake using our validators directly and remain in control of your funds at all times.',
                  'Avoid intermediaries and receive higher rewards.',
                  'Leverage our un-matched multi-layered security to ensure the safety of your funds.',
                ]}
                icon={OfferImage}
              />
              <Offer
                title="Custodial Staking"
                text={[
                  'Easily onboard and track rewards, balances, and key stats on one dashboard.',
                  'Mitigate risks, as we take care of security, optimization, and availability.',
                  'Leverage our un-matched multi-layered security to ensure the safety of your funds. ',
                ]}
                icon={BoxImage}
              />
            </section>
          </div>
        </Grow>
        <Grow in={!!assets.length} timeout={3000}>
          <div className={classes.contactForm}>
            <Typography component="h6" className={classes.formTitle}>
              Get in Touch
            </Typography>
            <Typography className={classes.formSubtitle}>
              Interested in learning more about our staking services?
              <br /> Please fill out the contact form & our team will be in
              touch shortly.
            </Typography>
            <ContactForm
              result={result}
              submitting={submittingRequest as boolean}
              handleRequest={submitRequest as (params: StakingRequest) => void}
              availableAssets={assets}
              preselectAsset={asset}
            />
          </div>
        </Grow>
      </main>
      {asset && modal && (
        <RequestStakingModal
          result={result}
          submitting={submittingRequest as boolean}
          handleRequest={submitRequest as (params: StakingRequest) => void}
          open={modal}
          handleClose={handleModalClose}
          preselectedAmount={preselectedAmount}
          preselectedAsset={asset}
          availableAssets={assets}
        />
      )}
      {result && (
        <ResultPopup
          type={result}
          onClose={() => {
            setResult(undefined);
          }}
        />
      )}
    </>
  );
}

export default Staking;
